import { programs, actions } from "@metaplex/js";
import { PublicKey } from "@solana/web3.js";
import { toPublicKey, findProgramAddress } from "../util/util";
import {
  programIds,
  getSafetyDepositConfig,
  getAuctionExtended,
} from "./claim";

const {
  metaplex: { Store, AuctionManager, SafetyDepositConfig },
  metadata: { Metadata },
  auction: { Auction, AuctionExtended, BidderPot },
  vault: { Vault },
} = programs;
const VAULT_PREFIX = "vault";

export const getBuyButtonAuction = async (auctionPubkey, connection) => {
  const storeId = await Store.getPDA(
    new PublicKey("BA78Bc2pCPkNtRCBXFCJE53kAvwHmvB4iFpE3kQxquTP")
  );

  const auctionManagers = await AuctionManager.findMany(connection, {
    store: storeId,
  });
  const auctionManager = auctionManagers.filter(
    (am) => am.data.auction === auctionPubkey
  )[0];
  auctionManager.data.pubkey = auctionManager.pubkey;
  const auction = await auctionManager.getAuction(connection);
  const bidderMetadata = await auction.getBidderMetadata(connection);
  const getVault = await Vault.load(connection, auctionManager.data.vault);

  const vault = {
    pubkey: getVault.pubkey.toBase58(),
    account: getVault.info,
    info: getVault.data,
  };

  const auctionView = {
    auction,
    auctionManager: auctionManager.data,
    vault,
    store: {
      pubkey: storeId,
    },
    bidderMetadata: bidderMetadata[0],
  };

  return auctionView;
};

export const getClaimButtonAuction = async (auctionPubkey, connection) => {
  const storeId = await Store.getPDA(
    new PublicKey("BA78Bc2pCPkNtRCBXFCJE53kAvwHmvB4iFpE3kQxquTP")
  );

  const auctionManagers = await AuctionManager.findMany(connection, {
    store: storeId,
  });
  const auctionManager = auctionManagers.filter(
    (am) => am.data.auction === auctionPubkey
  )[0];
  auctionManager.data.pubkey = auctionManager.pubkey;
  const auction = await auctionManager.getAuction(connection);
  const getVault = await Vault.load(connection, auctionManager.data.vault);
  const myBidderPots = await auction.getBidderPots(connection);
  const bidderMetadata = await auction.getBidderMetadata(connection);
  const myBidRedemptionTicketPubkey = await actions.getBidRedemptionPDA(
    auction.pubkey,
    bidderMetadata[0].pubkey
  );

  const safetyDepositBoxes = await getVault.getSafetyDepositBoxes(connection);
  const vault = {
    pubkey: getVault.pubkey.toBase58(),
    account: getVault.info,
    info: getVault.data,
  };

  const transferAuthority = (
    await findProgramAddress(
      [
        Buffer.from(VAULT_PREFIX),
        toPublicKey(programIds().vault).toBuffer(),
        toPublicKey(auctionManager.data.vault).toBuffer(),
      ],
      toPublicKey(programIds().vault)
    )
  )[0];

  const safetyDepositConfig = await getSafetyDepositConfig(
    auctionManager.pubkey.toBase58(),
    safetyDepositBoxes[0].pubkey.toBase58()
  );

  const auctionExtended = await getAuctionExtended({
    auctionProgramId: programIds().auction,
    resource: auctionManager.data.vault,
  });

  const auctionView = {
    auction,
    auctionManager: auctionManager.data,
    vault,
    safetyDepositBoxes,
    myBidderPot: myBidderPots[0],
    bidderMetadata: bidderMetadata[0],
    myBidRedemptionTicket: {
      pubkey: myBidRedemptionTicketPubkey,
    },
    store: {
      pubkey: storeId,
    },
    transferAuthority: {
      pubkey: toPublicKey(transferAuthority),
    },
    safetyDepositConfig: {
      pubkey: toPublicKey(safetyDepositConfig),
    },
    auctionExtended: {
      pubkey: toPublicKey(auctionExtended),
    },
  };

  return auctionView;
};

export const getAuctionView = async (
  auctionPubkey,
  connection,
  shopAddress
) => {
  const storeId = await Store.getPDA(new PublicKey(shopAddress.toBase58()));
  const auctionManagers = await AuctionManager.findMany(connection, {
    store: storeId,
  });
  const auctionManager = auctionManagers.filter(
    (am) => am.data.auction === auctionPubkey
  )[0];
  auctionManager.data.pubkey = auctionManager.pubkey;
  const auction = await auctionManager.getAuction(connection);
  const getVault = await Vault.load(connection, auctionManager.data.vault);
  const vault = {
    pubkey: getVault.pubkey.toBase58(),
    account: getVault.info,
    info: getVault.data,
  };
  const bidderMetadata = await auction.getBidderMetadata(connection);

  if (bidderMetadata.length === 0) {
    const auctionView = {
      auction,
      auctionManager: auctionManager.data,
      vault,
      store: {
        pubkey: storeId,
      },
      bidderMetadata: bidderMetadata[0],
    };

    return auctionView;
  }

  const myBidderPots = await auction.getBidderPots(connection);
  const myBidRedemptionTicketPubkey = await actions.getBidRedemptionPDA(
    auction.pubkey,
    bidderMetadata[0].pubkey
  );

  const safetyDepositBoxes = await getVault.getSafetyDepositBoxes(connection);

  const transferAuthority = (
    await findProgramAddress(
      [
        Buffer.from(VAULT_PREFIX),
        toPublicKey(programIds().vault).toBuffer(),
        toPublicKey(auctionManager.data.vault).toBuffer(),
      ],
      toPublicKey(programIds().vault)
    )
  )[0];

  const safetyDepositConfig = await getSafetyDepositConfig(
    auctionManager.pubkey.toBase58(),
    safetyDepositBoxes[0].pubkey.toBase58()
  );

  const auctionExtended = await getAuctionExtended({
    auctionProgramId: programIds().auction,
    resource: auctionManager.data.vault,
  });

  const auctionView = {
    auction,
    auctionManager: auctionManager.data,
    vault,
    safetyDepositBoxes,
    myBidderPot: myBidderPots[0],
    bidderMetadata: bidderMetadata[0],
    myBidRedemptionTicket: {
      pubkey: myBidRedemptionTicketPubkey,
    },
    store: {
      pubkey: storeId,
    },
    transferAuthority: {
      pubkey: toPublicKey(transferAuthority),
    },
    safetyDepositConfig: {
      pubkey: toPublicKey(safetyDepositConfig),
    },
    auctionExtended: {
      pubkey: toPublicKey(auctionExtended),
    },
  };

  return auctionView;
};
