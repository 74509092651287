import React, { useState } from "react";
import { connectAutoComplete, Stats, Snippet } from "react-instantsearch-dom";
import SearchIcon from "../../../unified-search/components/UI/search-icon";
import AutocompleteHits from "./autocomplete-hits";

const Autocomplete = ({ hits, currentRefinement, refine, openModal, defaultPicture, custom }) => {
  const [show, toggle] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const onSearchClick = (e) => {
    e.preventDefault();
    openModal();
  };

  const OnBlur = () => {
    setTimeout( // set timeout to make click events trigger first before on blur
      () => toggle(false), 
      500
    );
  };

  const handleClick = skuSelected => {
    setInputValue(skuSelected);
  }

  const handleOnChange = (value) => {
    setInputValue(value);
    refine(value);
  }

  return (
    <>
      <div
        className="aa-Autocomplete"
        role="combobox"
        aria-expanded="false"
        aria-haspopup="listbox"
        aria-labelledby="autocomplete-2-label"
        onFocus={() => toggle(true)}
        onBlur={OnBlur}
      >
        <div className="aa-Form" style={{paddingLeft: "10px", paddingRight: "36px"}}>
          <div className="aa-InputWrapper">
            <input
              className="aa-Input"
              aria-autocomplete="both"
              aria-labelledby="autocomplete-0-label"
              id="autocomplete-0-input"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              enterKeyHint="search"
              spellCheck="false"
              placeholder=""
              maxLength="512"
              type="search"
              value={inputValue}
              onChange={(event) => handleOnChange(event.currentTarget.value)}
              style={{outline: 'unset !important'}}
              data-sku={inputValue}
            />
          </div>
          <div className="aa-InputWrapperSuffix" style={{left: "unset", right: "0"}}>
            <label className="aa-Label">
                <button className="aa-SubmitButton" onClick={onSearchClick}>
                  <div className="search-button-icon" style={{marginTop: "-5px"}}>
                    <SearchIcon height="7" width="10"/>
                  </div>
                </button>
            </label>
          </div>
        </div>
      </div>

      {show && (
        <AutocompleteHits
          hits={hits}
          currentRefinement={currentRefinement}
          openModal={openModal}
          refine={refine}
          defaultPicture={defaultPicture}
          custom={custom}
          handleClick={handleClick}
        />
      )}
    </>
  );
};
export default connectAutoComplete(Autocomplete);
