import React from "react";
import Stats from "./stats";

const HitsHeader = ({}) => {
  return (
    <>
      <div className="unified-stats-container">
        <div className="uni-BodyHeader-stats">
          <Stats />
        </div>
      </div>
    </>
  );
};

export default HitsHeader;
