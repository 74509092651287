import React from 'react';
import {
    useConnection,
    useWallet,
} from '@solana/wallet-adapter-react';
import {
    WalletModalProvider,
    WalletMultiButton,
} from '@solana/wallet-adapter-react-ui';
// import Checkout from './checkout'
// import Checkout2 from './checkout2'
import Buy from './buy'
import { RotatingLines, TailSpin  } from  'react-loader-spinner'
import $ from "jquery";
import {displayText} from "../../utils/utils";

const MyWallet = ({productData}) => {
    const { connection } = useConnection();
    let walletAddress = "";

    // if you use anchor, use the anchor hook instead
    // const wallet = useAnchorWallet();
    // const walletAddress = wallet?.publicKey.toString();
    $('.send-btn').attr('disabled', 'disabled');
    const wallet = useWallet();
    if (wallet.connected && wallet.publicKey) {
        walletAddress = wallet.publicKey.toString()
    }else{
        $('.solana-content-error').removeClass('NFT-SOLD solana-error solana-success').html('');
    }
    if( !productData.products.is_visible ){
        $('.solana-content').css('display','none');          
        displayText('This NFT is already sold.','solana-error');    
    }

    return (
        <>
            <div className="solana-multi-wrapper multi-wrapper">
            <div className='buttonContainer'>
                <div className='solana-loader'>
                    <TailSpin color="#808080" height={50} width={50}/>
                </div>
                <div className='solana-content-error NFT-styles'></div>
                <div className='solana-content'>
                    {wallet.connected &&
                        <p className='truncate'>Your wallet is {walletAddress}.</p>
                    }
                    <span className="solana-button-wrapper button-wrapper">
                        <WalletModalProvider>
                            <WalletMultiButton />
                        </WalletModalProvider>
                        {wallet.connected && <Buy productData={productData}/> }
                        {/* {wallet.connected && <Checkout productData={productData}/> }
                        <Checkout2 productData={productData}/> */}
                    </span>
                    {/* {wallet.connected && <WalletDisconnectButton />} */}
                </div>
                </div>
            </div>
        </>
    );
};

export default MyWallet;
