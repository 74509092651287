import { AccountLayout, u64, MintLayout } from "@solana/spl-token";
import BN from "bn.js";
import { PublicKey } from "@solana/web3.js";
import {
  ensureWrappedAccount,
  approve,
  toPublicKey,
  pubkeyToString,
  placeBid,
} from "../util/util";

import { sendTransactionWithRetry } from "../util/connection";

const getTokenAccount = async (pubKey, connection) => {
  const accountInfo = await connection.getAccountInfo(pubKey);
  const tokenAccount = wrapNativeAccount(pubKey.toBase58(), accountInfo);

  return tokenAccount;
};

const wrapNativeAccount = (pubkey, account) => {
  if (!account) {
    return undefined;
  }
  const WRAPPED_SOL_MINT = new PublicKey(
    "So11111111111111111111111111111111111111112"
  );
  const key = new PublicKey(pubkey);

  return {
    pubkey: pubkey,
    account,
    info: {
      address: key,
      mint: WRAPPED_SOL_MINT,
      owner: key,
      amount: new u64(account.lamports),
      delegate: null,
      delegatedAmount: new u64(0),
      isInitialized: true,
      isFrozen: false,
      isNative: true,
      rentExemptReserve: null,
      closeAuthority: null,
    },
  };
};

const deserializeMint = (data) => {
  if (data.length !== MintLayout.span) {
    throw new Error("Not a valid Mint");
  }

  const mintInfo = MintLayout.decode(data);

  if (mintInfo.mintAuthorityOption === 0) {
    mintInfo.mintAuthority = null;
  } else {
    mintInfo.mintAuthority = new PublicKey(mintInfo.mintAuthority);
  }

  mintInfo.supply = u64.fromBuffer(mintInfo.supply);
  mintInfo.isInitialized = mintInfo.isInitialized !== 0;

  if (mintInfo.freezeAuthorityOption === 0) {
    mintInfo.freezeAuthority = null;
  } else {
    mintInfo.freezeAuthority = new PublicKey(mintInfo.freezeAuthority);
  }

  return mintInfo;
};

const MintParser = (pubKey, info) => {
  const buffer = Buffer.from(info.data);

  const data = deserializeMint(buffer);

  const details = {
    pubkey: pubKey,
    account: {
      ...info,
    },
    info: data,
  };

  return details;
};

export const setupPlaceBid = async (
  connection,
  wallet,
  bidderTokenAccount,
  auctionView,
  amount,
  overallInstructions,
  overallSigners
) => {
  let signers = [];
  let instructions = [];
  const cleanupInstructions = [];

  const accountRentExempt = await connection.getMinimumBalanceForRentExemption(
    AccountLayout.span
  );

  const tokenAccount = await getTokenAccount(bidderTokenAccount, connection);

  const mintInfo = await connection.getAccountInfo(tokenAccount.info.mint);

  const mint = MintParser(tokenAccount.info.mint.toBase58(), mintInfo);

  const lamports = accountRentExempt + amount;

  let bidderPotTokenAccount;
  let receivingSolAccountOrAta = ensureWrappedAccount(
    instructions,
    cleanupInstructions,
    tokenAccount,
    wallet.publicKey,
    lamports + accountRentExempt * 2,
    signers
  );

  const transferAuthority = approve(
    instructions,
    cleanupInstructions,
    toPublicKey(receivingSolAccountOrAta),
    wallet.publicKey,
    lamports - accountRentExempt
  );

  signers.push(transferAuthority);

  const bid = new BN(lamports - accountRentExempt);
  await placeBid(
    wallet.publicKey.toBase58(),
    pubkeyToString(receivingSolAccountOrAta),
    bidderPotTokenAccount,
    auctionView.auction.data.tokenMint,
    transferAuthority.publicKey.toBase58(),
    wallet.publicKey.toBase58(),
    auctionView.auctionManager.vault,
    bid,
    instructions
  );

  overallInstructions.push([...instructions, ...cleanupInstructions.reverse()]);
  overallSigners.push(signers);
  return bid;
};
