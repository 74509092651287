import React, { Component } from 'react';
import { render } from 'react-dom';
import { Tab, Tabs, TabList, TabPanel, TabProvider } from 'react-tabs'
import getCustomAttr from './getCustomAttr';
import GetCustomAttr from "./getCustomAttr";

export default function accordion({productData}) {
    const attr = getCustomAttr(productData.products.custom_fields,'Attributes')
    const attributes = attr.substring(2, attr.length-2).split('},{');
    const hasAttributes = attr.includes('trait_type');
    return (
      <Tabs defaultTab="one">
        <section className="my-tabs">
         
          <TabList className="my-tablist">
            <Tab tabFor="one">Description</Tab>
            <span className="divider"></span>
            <Tab tabFor="two">Attributions</Tab>
            <span className="divider"></span>
            <Tab tabFor="three">Details</Tab>
          </TabList>
          <div className="wrapper">
            <TabPanel tabId="one">
              <p>{productData.products.description ? productData.products.description : 'Description is not available on this NFT.'}</p>
            </TabPanel>
            <TabPanel tabId="two">
              <p className='NFT-attr'>
              {hasAttributes && <div className="NFT-attributes-cards">
                    {attributes.map((data,index) => {
                        let value = []
                        let text = data.split(',');
                     const  test = data.split(':')[1].replace(/"/g,'')
                      console.log(test)
                    return (
                      
                      <div className='att-cards'> 
                      <div key={index}
                      //  className={`NFT-attributes-card-${(index+1)} attributesBox`}
                      style={{border: '1px solid rgba(0, 0, 0, 0.05)', display: 'block', width: '200px'}}>
                      {text.map((data2,index2) =>(
                            <div style={{margin: '20px'}}>
                              <div key={index2}
                              //  className={`NFT-attributes-card${(index+1)}-attr${(index2+1)}`}
                              style={{ textAlign: 'center', color: index2 === 0 ? 'gray' : 'black', whiteSpace: 'nowrap'}} >
                                      {data2.split(':')[1].replace(/"/g,'')}
                              </div>    
                            </div>
                      ))}
                          {/* <div className='attributes'>
                            <div  className='edition'>Edition</div>
                              <div className='attrValue'>
                                <div>1 of 5</div>
                              <div>46% have this trait</div>
                            </div>
                          </div> */}

                    </div>
                    </div>
                    )
                    })}
                </div>}
                {!hasAttributes && <div className="NFT-attributes-no">
                    No properties available for this NFT.
                </div>}
              </p>
            </TabPanel>
            <TabPanel tabId="three">
                <div className='attrValue'>
                    <div className='addresses'>Mind Address:</div><p className=''>{getCustomAttr(productData.products.custom_fields,'Mint Address')}</p>
                    <div className='addresses'>Token Program:</div><p className=''>{getCustomAttr(productData.products.custom_fields,'Token Program')}</p>
                  </div>
            </TabPanel>
          </div>
        </section>
        </Tabs>
    );
}