import React, { useState } from "react";
import { typesenseSearchClient } from "../utils/typesenseSearchClient";
import { Hits, InstantSearch } from "react-instantsearch-dom";

import CustomHits from "./components/hits"
import CustomSearchBox from "./components/search-box";

const App = ({ config }) => {
    const searchClient = typesenseSearchClient(config).searchClient;
    const [visible, setVisible] = useState(false)
    const [searchState, setSearchState] = useState({ query: "" })

    const setSearchValue = (value) => {
        setSearchState({ query: value })
    }

    return (
        <InstantSearch searchClient={searchClient} indexName={config.index} searchState={searchState}>
            <CustomSearchBox
                setVisible={setVisible}
                setSearchValue={setSearchValue}
            />
            <CustomHits
                hits={Hits}
                visible={visible}
                setSearchValue={setSearchValue}
                defaultImage={config.settings.defaultImage}
            />
        </InstantSearch>
    );
};

export default App;
