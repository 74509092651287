import React from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { RotatingLines } from "react-loader-spinner";
import Buy from './buy';

const MyWallet = () => {
  let walletAddress = "";

  const wallet = useWallet();
  if (wallet.connected && wallet.publicKey) {
    walletAddress = wallet.publicKey.toString();
  }

  return (
    <>
      <div className="solana-multi-wrapper multi-wrapper">
        <div className="solana-loader">
          <RotatingLines width="100" strokeColor="#512da8" />
        </div>
        <div className="solana-content">
          {(wallet.connected && <p className="truncate"> Your wallet is {walletAddress}.</p>) || (
            <p>Click to connect your wallet.</p>
          )}
          <span className="solana-button-wrapper button-wrapper">
            <WalletModalProvider>
              <WalletMultiButton />
            </WalletModalProvider>
            {wallet.connected && <Buy/> }
          </span>
        </div>
      </div>
    </>
  );
};

export default MyWallet;
