import React from "react";
import { render } from "react-dom";
import "instantsearch.css/themes/satellite.css";
import "@algolia/autocomplete-theme-classic/dist/theme.css";

import "./index.scss";
import Unified from "./unified-search/Unified";
import UnifiedTypesense from "./unified-search-typesense/UnifiedTypesense";
import OSGTypesense from "./osg/OSGTypesense";
import ViewNFT from "./solana/app";
import Metaplex from "./metaplex/app";
import Kimball from "./kimball/app";
import KimballQuickOrder from "./kimball-quick-order/app";
import { CreateContainer, AddAttributes, AppendAttribute } from "./searchFunction";
import { MountNFT } from "./mountNFT";
import OsgFindDistributor from "./osgFindDistributor/app"
import StateContextProvider from "./osgFindDistributor/context/StateContextProvider";
import OsgBlogSearch from "./osgBlogSearch/app"
import OsgCartQuickOrder from "./osgCartQuickOrder/app"

window.createContainer = () => {
  CreateContainer();
};

window.addAttributes = () => {
  AddAttributes();
};

window.appendAttributes = () => {
  AppendAttribute();
};

window.MountNFT = () => {
  MountNFT();
};

window.unifiedFour13 = (
  appID,
  searchKey,
  container,
  settings = {},
  custom = "none"
) => {
  let s = {
    bgHeaderColor: "#5468ff",
  };

  s = { ...s, ...settings };

  let config = {
    appID,
    searchKey,
    setting: s,
    custom,
  };

  return render(
    <React.StrictMode>
      <Unified config={config} />
    </React.StrictMode>,
    document.getElementById(container)
  );
};

window.ViewNFT = (productData) => {
  return render(
    <React.StrictMode>
      <ViewNFT productData={productData} />
    </React.StrictMode>,
    document.getElementById("NFT-PDP")
  );
};

window.metaplex = (productData) => {
  return render(
    <React.StrictMode>
      <Metaplex></Metaplex>
    </React.StrictMode>,
    document.getElementById("metaplex")
  );
};

window.viewModels = (
  container,
  index,
  server,
  options
  ) => {

    let config = {
      container,
      index,
      server,
      options
    };

    return render(
      <React.StrictMode>
        <Kimball config={config}></Kimball>
      </React.StrictMode>,
      document.getElementById(container)
    );
};

window.viewQuickOrder = (
  container,
  index,
  server,
  options
  ) => {

    let config = {
      container,
      index,
      server,
      options
    };

    return render(
      <React.StrictMode>
        <KimballQuickOrder config={config}></KimballQuickOrder>
      </React.StrictMode>,
      document.getElementById(container)
    );
}; 


window.unifiedFour13Typesense = (
  container,
  index,
  server,
  options,
  settings = {},
  custom = "none"
) => {
  let s = {
    bgHeaderColor: "#5468ff",
  };

  s = { ...s, ...settings };

  let config = {
    container,
    index,
    server,
    options,
    setting: s,
    custom,
  };

  return render(
    <React.StrictMode>
      <UnifiedTypesense config={config} />
    </React.StrictMode>,
    document.getElementById(container)
  );
};

window.osgTypesense = (
  container,
  documents,
  server,
  options,
  settings = {},
  custom = "none"
) => {
  let s = {
    bgHeaderColor: "#5468ff",
  };

  s = { ...s, ...settings };

  let config = {
    container,
    documents,
    server,
    options,
    setting: s,
    custom,
  };

  return render(
    <React.StrictMode>
      <OSGTypesense config={config} />
    </React.StrictMode>,
    document.getElementById(container)
  );
};

window.osgFindDistributor = (
  container,
  index,
  server,
  key
  ) => {

    let props = {
      container,
      index,
      server,
      key
    };

    return render(
      <React.StrictMode>
        <StateContextProvider props={props}>
          <OsgFindDistributor props={props}></OsgFindDistributor>
        </StateContextProvider>
      </React.StrictMode>,
      document.getElementById(container)
    );
}; 

window.osgBlogSearch = (
  container,
  server,
  index,
  options,
) => {

  let config = {
    container,
    index,
    server,
    options,
  };

  return render(
    <React.StrictMode>
      <OsgBlogSearch config={config} />
    </React.StrictMode>,
    document.getElementById(container)
  );
};

window.osgCartQuickOrder = (
  container,
  server,
  index,
  options,
  settings,
) => {

  let config = {
    container,
    index,
    server,
    options,
    settings,
  };

  return render(
    <React.StrictMode>
      <OsgCartQuickOrder config={config} />
    </React.StrictMode>,
    document.getElementById(container)
  );
};
