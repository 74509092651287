import $ from "jquery";
import ProductData from "./utils/getProduct";
import { RotatingLines } from "react-loader-spinner";
import * as ReactDOM from "react-dom";

export const MountNFT = () => {
  function showLoader() {
    let div = document.createElement("div");
    div.className = "mount-loader";
    ReactDOM.render(
      <RotatingLines />,
      document.getElementById("NFT-PDP").appendChild(div)
    );
  }

  //remove button and price for product details LIST
  $(".listItem-title a").each((index, element) => {
    const pName = $(element).html();
    if (pName.includes("NFT")) {
      $(element).parents(".listItem-body").find(".listItem-rating").hide();
      $(element).parents(".listItem-body").find(".listItem-actions").hide();
    }
  });

  //remove button and price for product details GRID
  $(".card-title a").each((index, element) => {
    const pName = $(element).html();
    if (pName.includes("NFT")) {
      $(element).parents(".card-body").find(".price").hide();
      $(element)
        .parents(".card")
        .find(".card-figcaption-button")
        .not(".quickview")
        .hide();
      if ($(".tabs-contents").length > 0) {
        $(element).parents(".card").find(".card-figcaption-body").hide();
      }
    }
  });

  setInterval(function () {
    let productId = $("[name='product_id']").attr("value");
    let productSku = $("[data-product-sku]").text();

    if (
      $(".productView").length > 0 &&
      typeof productId !== "undefined" &&
      $("#NFT-PDP").length === 0 &&
      productSku.includes("NFT")
    ) {
      console.log("check productView");
      $(".productView").replaceWith("<div id='NFT-PDP'></div>");
      if ($(".mount-loader").length === 0) {
        showLoader();
        $(".mount-loader").css("text-align", "center");
      }
      void (async function () {
        let data = await ProductData(productId);
        // console.log(data);
        if (data.products.sku.includes("NFT")) {
          $(".mount-loader").css("display", "none");
          $("#NFT-PDP").addClass("productView");
          ViewNFT(data);
        }
      })();
    }
  }, 100);
};
