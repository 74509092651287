import React from "react";
import { connectHits } from "react-instantsearch-dom";
import Hit from "./hit";

const Hits = ({ hits }) => {
  // PUT THE IS MOBILE LOGIC HERE
  return (
    <>
      <div className="ais-Hits">
        <ol className="unified-custom-hits">
          {hits.map((hit) => (
            <li key={hit.objectID} className="">
              <Hit hit={hit} />
            </li>
          ))}
        </ol>
      </div>
    </>
  );
};

export default connectHits(Hits);
