import React from "react";
import SalesMan from "./salesman";
import LocalDistributor from "./local";
import NationalDistributor from "./national";
import { useStateContext } from "../context/StateContextProvider";

const Hit = ({ salesMan, local, nationalCount, nationalData }) => {
  const { distanceInKM } = useStateContext();

  return (
    <>
      <div id="info-container">
        {
          salesMan.length > 0 ? (
            salesMan.map((sales) => (
              <div key={sales.objectID} id={sales.objectID} className="card sales-man">
                <SalesMan salesMan={sales} />
              </div>
            ))
          ) :
          (
            <div className="card sales-man">
              <p>No sales representative found.</p>
            </div>
          )
        }
        <div className="accordions local-distributor">
          <LocalDistributor localDistributors={local} distanceInKM={distanceInKM} />
        </div>
        
        <NationalDistributor nationalCount={nationalCount} nationalData={nationalData} />
      </div>
    </> 
  );
};

export default Hit;