import React from "react";
import {
  HierarchicalMenu,
  RefinementList,
  Panel,
  ClearRefinements
} from "react-instantsearch-dom";

import $ from "jquery";
import { CloseIcon } from "../../unified-search/components/UI/close-icon";
import HitsHeader from "../../unified-search/components/hits-header";
import { Slider } from "../../unified-search/components/Slider";

const refinements = ({ setting }) => {

  function CustomWidget({ attribute, limit}) {
    let facetDisplay = null;
    let header = attribute;

    switch (attribute) {
      case "categories":
        if (setting.categoriesSuggestion) {
          header = attribute;
          facetDisplay = (
            <HierarchicalMenu
              showMore
              attributes={[
                attribute,
              ]}
              limit={limit}
            />
          );
        } else {
          header = "";
        }

        break;
      case "brand":
        header = attribute;
        facetDisplay = <RefinementList attribute={attribute} limit={limit} showMore />;
        break;
      case "price":
        const options = {
          attribute: "price",
        };
        facetDisplay = <Slider attribute={options.attribute} />;
        break;
      default:
        header = attribute;
        facetDisplay = <RefinementList attribute={attribute} limit={limit} showMore />;
    }
    return <Panel header={header}>{facetDisplay}</Panel>;
  }


  function closeMobileFilter() {
    $(".unified-container__refinements").removeClass("ref-filter-mobile");
    $(".unified-search-header").attr("style", "z-index: 2 !important");
  }
  return (
    <>
      <div className="">
        <div className="refinement-wrapper">
          <div className="close-filter-mobile" onClick={closeMobileFilter}>
            <CloseIcon />
          </div>
          <div className="unified-refinements__content__header">
            <h2 className="unified-refinements__content__header--title">
              Filters
            </h2>
            <div
              className="unified-refinements__content__header--clear-filters"
              data-layout="desktop"
            >
              <ClearRefinements
                translations={{
                  reset: (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                      >
                        <g fill="none" fillRule="evenodd" opacity=".4">
                          <path d="M0 0h11v11H0z" />
                          <path
                            fill="#000"
                            fillRule="nonzero"
                            d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                          />
                        </g>
                      </svg>
                      Clear filters
                    </>
                  ),
                }}
              />
            </div>
          </div>
          <div>
            {
            setting.facet_by && setting.facet_by.map((i, index) =>  {              
              return <CustomWidget key={index} attribute={i} limit={setting.max_facet_values} />
            })
            }
          </div>
        </div>  
        <div className="ref-mobile-filter-cnt">
          <button
            className="ref-mobile-filter-close-btn"
            onClick={closeMobileFilter}
          >
            Close filter
          </button>
          <button className="ref-mobile-filter-btn" onClick={closeMobileFilter}>
            <HitsHeader />
          </button>
        </div>
      </div>
    </>
  );
};

export default refinements;
