import { faCaretDown, faCaretUp, faGlobe, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useStateContext } from "../context/StateContextProvider";

const NationalDistributor = ({ nationalCount }) => {
  const { nationalData, accordionNational, isNationalCollapsed } = useStateContext();

  const renderNationalDist = () => {
    if (nationalCount > 0) {
      return nationalData.map(({ document }) => {
        const { id, companyName, phone, website } = document;
        return ( 
            <div key={id} id={id} className={`accordion-body accordion-body--national ${isNationalCollapsed ? 'collapsed' : ''}`}>
                <div className="accordion-item accordion-distance national-distance">
                    <FontAwesomeIcon className="accordion-icon" icon={faGlobe} />
                </div>
                <h3>{companyName}</h3>
                {
                    phone && (
                        <p className="accordion-item accordion-phone national-phone">
                            <FontAwesomeIcon icon={faPhone} />
                            <span>{phone}</span>
                        </p>
                    )
                }
                {
                    website && (
                        <p className="accordion-item accordion-web national-url">
                            <FontAwesomeIcon icon={faGlobe} />
                            <a href={`${website}`}>{website}</a> 
                        </p>
                    )
                }
            </div> 
        )
    });
    } else {
      return (
        <div className={`accordion-body accordion-body--national ${isNationalCollapsed && 'collapsed'}`}>
          <p>No national distributor found.</p>
        </div>
      );
    }
  };

  return (
    <>
      <div className="accordions national-distributor">
        <div className="accordion-header accordion-header--national" id="nationalDistributor" onClick={accordionNational}>
          <span>National Distributor ({nationalCount})</span>
          <FontAwesomeIcon className="accordion-header-icon" icon={isNationalCollapsed ? faCaretDown : faCaretUp} />
        </div>
        {renderNationalDist()}
      </div>
    </>
  );
};

export default NationalDistributor;