import { connectHits } from "react-instantsearch-dom";
import { Marker } from 'react-instantsearch-dom-maps';
import { useStateContext } from "../context/StateContextProvider";

const Hits = ({ hits }) => {
  const { baseURl } = useStateContext();
  const activeCustomers = [];
  const { nationalData } = useStateContext();
  console.log("all results", nationalData)

  hits.forEach( async (h) => {
    const termsRes = await fetch(`${baseURl}getB2BCompaniesByName?companyName=${h.companyName}%26isIncludeExtraFields=1`)
    const data = await termsRes.json();

    const company = data.companies.filter((d) => d.companyName.toLowerCase() === h.companyName.toLowerCase());
    company.forEach((c) => {
        const statement = c.extraFields?.find(e=>e.fieldName=='Account Statement')
        const parsedStatement = statement && statement.fieldValue ? JSON.parse(statement.fieldValue) : {};
        const terms = (parsedStatement["Terms"] ?? "").toLowerCase();
        if (!terms.includes('inactive') || !terms.includes('terminated')) {
            activeCustomers.push(h)
        }
    })
  })

  const showDistributorCards = (id) => {
    const card = document.getElementById(`${id}`);
  
    if (card) {
      card.classList.add('highlight');
  
      card.style.background = '#cccccc';
  
      // Remove glow/shadow class and reset background color after 2 seconds
      setTimeout(() => {
        card.classList.remove('highlight');
        if (card.classList.contains('sales-man')) {
          card.style.background = '#e8f4ff';
        } else {
          card.style.background = '#ffffff';
        }
      }, 1000);
    }
  };

  const renderMarkers = (filteredHits, isSalesman) => {
    return filteredHits.map((hit) => {
      return ( <Marker
        key={hit.id}
        hit={{
          ...hit,
          _geoloc: {
            lat: hit.coordinates[0],
            lng: hit.coordinates[1],
          }
        }}
        onClick={() => { showDistributorCards(hit.id, isSalesman) }}
      /> )
    });
  };

  

  return (
    <>
      {renderMarkers(activeCustomers, false, false)}
      {renderMarkers(hits.filter(hit => hit.customerType === 'salesman'), true, false)}
      {
        nationalData.map(({document}) => {
          const { id, coordinates } = document;
          return (
            <Marker
              key={id}
              hit={{
                ...document,
                _geoloc: {
                  lat: coordinates[0],
                  lng: coordinates[1],
                }
              }}
              onClick={() => { showDistributorCards(id) }}
            />
          )
        })
      }
    </>
  );
};

export default connectHits(Hits);