
import { typesenseSearchClient } from "../utils/typesenseSearchClient"
import { InstantSearch, Hits, Configure  } from "react-instantsearch-dom";
import {
    GoogleMapsLoader,
    GeoSearch
} from 'react-instantsearch-dom-maps';
import Search from "./components/search";
import CustomHits from "./components/hits";
import { useStateContext } from "./context/StateContextProvider";

const App = ({ props }) => {
    const { searchCoord } = useStateContext();

    let config = {
      server: {
        ...props.server
      },
      options: {
        q: '*',
        query_by: 'address',
        filter_by: `coordinates:(${searchCoord.lat}, ${searchCoord.lng}, ${searchCoord.radius} mi)`,
      },
      coords: {
        geoloc: 'coordinates'
      }
    }

    const searchClient = typesenseSearchClient(config).searchClient;

    return (
        <>
            <h2 className="findDist-header">Find Your Local Distributor</h2>
            <InstantSearch searchClient={searchClient} indexName={props.index}>
                <Configure
                  hitsPerPage={20}
                />
                <div className="findDist-container">
                  <Search hits={Hits} props={props} />
                  <GoogleMapsLoader apiKey={props.key}>
                    {google => (
                      <GeoSearch 
                          google={google}
                          initialZoom={8}
                          zoomControl={false}
                          enableClearMapRefinement={true}
                          enableRefineControl={true}
                          initialPosition={{
                              lat: searchCoord.lat,
                              lng: searchCoord.lng,
                          }}
                      >
                        {() => <CustomHits hits={Hits} />}
                      </GeoSearch>
                    )}
                  </GoogleMapsLoader>
                </div>
            </InstantSearch>
        </>
    );
};

export default App;