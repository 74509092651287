import { typesenseSearchClient } from "../utils/typesenseSearchClient"
import { InstantSearch, Hits, MenuSelect  } from "react-instantsearch-dom";

import SearchButton from "./components/custom-searchbox";

const App = ({ config }) => {

    const searchClient = typesenseSearchClient(config).searchClient;

    function closeModal() {
        setIsOpen(false);
      }
    
      function openModal() {
        setIsOpen(true);
      }

    return (
        <>
            <InstantSearch searchClient={searchClient} indexName={config.index}>
                <SearchButton
                    openModal={openModal}
                    defaultPicture={config.options.default_picture_url}
                    custom={config.options.custom}
                />
            </InstantSearch>
        </>
    );


};

export default App;