import React, { useState } from "react";
import { connectAutoComplete, Stats, Snippet, Index, Configure, Hits } from "react-instantsearch-dom";
import SearchIcon from "../../../unified-search/components/UI/search-icon";
import DisplayHits from "./displayhits";

const Autocomplete = ({ hits, currentRefinement, refine, openModal, defaultPicture, custom, documents, searchPlaceholder }) => {
  const [show, toggle] = useState(false);
  
  const onSearchClick = (e) => {
    e.preventDefault();
    openModal();
  };

  const OnBlur = () => {
    setTimeout( // set timeout to make click events trigger first before on blur
      () => toggle(false), 
      500
    );
  };

  return (
    <>
      <div
        className="aa-Autocomplete"
        role="combobox"
        aria-expanded="false"
        aria-haspopup="listbox"
        aria-labelledby="autocomplete-2-label"
        onFocus={() => toggle(true)}
        onBlur={OnBlur}
      >
        <div className="aa-Form aa-Form-custom">
          <div className="aa-InputWrapper">
            <input
              className="aa-Input"
              aria-autocomplete="both"
              aria-labelledby="autocomplete-0-label"
              id="autocomplete-0-input"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              enterKeyHint="search"
              spellCheck="false"
              placeholder={searchPlaceholder}
              maxLength="512"
              type="search"
              value={currentRefinement}
              onChange={(event) => refine(event.currentTarget.value)}
              style={{outline: 'unset !important'}}
            />
          </div>
          <div className="aa-InputWrapperSuffix">
            <label className="aa-Label">
              { (custom == "osg") ? 
                <button className="aa-SubmitButton search-button" onClick={onSearchClick}>
                  <div className="search-button-icon">
                    <SearchIcon height="20" width="20"/>
                  </div>
                </button>
              :
                <button className="aa-SubmitButton" onClick={onSearchClick}>
                  <SearchIcon height="20" width="20" />
                </button>
              }
            </label>
          </div>
        </div>
      </div>

      {show && (
        <>
            <Index indexName={documents.series}>
              <Configure hitsPerPage={6} />
            </Index>

            <Index indexName={documents.categories}>
              <Configure hitsPerPage={6} />
            </Index> 

            <Index indexName={documents.pages}>
              <Configure hitsPerPage={6} />
            </Index>

            <Index indexName={documents.blogs}>
              <Configure hitsPerPage={6} />
            </Index> 

            <Index indexName={documents.products}>
              <Configure hitsPerPage={6} />
            </Index> 

            <DisplayHits hits={hits} currentRefinement={currentRefinement} openModal={openModal} defaultPicture={defaultPicture}/>
        </>
      )}
    </>
  );
};
export default connectAutoComplete(Autocomplete);
