import React, { useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { typesenseSearchClient } from "../utils/typesenseSearchClient"
import { InstantSearch, Hits, connectCurrentRefinements, Index, Configure, SearchBox} from "react-instantsearch-dom";

import Header from "../unified-search/components/header";
import SearchButton from "./components/searchButton";
import Footer from "../unified-search/components/footer";
import Refinements from "./components/refinements";
import CustomHits from "./components/hits";
import HitsHeader from "../unified-search/components/hits-header";
import FilterCount from "../unified-search/components/filter-count";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

const App = ({ config }) => {
  let [isOpen, setIsOpen] = useState(false);
  
  const searchClient = typesenseSearchClient(config).searchClient;

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function openMobileFilter() {
    $('.unified-container__refinements').addClass('ref-filter-mobile').attr('style','z-index: 99999 !important');
    $('.unified-search-header').attr('style','z-index: -1 !important');
  }

  const CustomCurrentRefinements = connectCurrentRefinements(FilterCount);

  useEffect(() => {
    const searchText = config.setting.searchPlaceholder
      ? config.setting.searchPlaceholder
      : "Search for product";
    const marginFilterRight =
      config.setting.filterDirection === "Right" ? "0px" : "60px";
    const marginFilterLeft =
      config.setting.filterDirection === "Right" ? "60px" : "0px";
    const filterDirection =
      config.setting.filterDirection === "Right" ? "row-reverse" : "row";
    const showFilter = config.setting.showFilter ? "flex" : "none";
    const headerColor = config.setting.bgHeaderColor;
    const tempColor = config.setting.tempColor;
    const productDescription = config.setting.productDescription ? 'block' : 'none';
    const displayPrices = config.setting.displayPrices ? '' : 'none';

    $(".algoliaText").html(searchText);
    document.documentElement.style.setProperty(
      "--my-custom-headercolor",
      headerColor
    );
    document.documentElement.style.setProperty(
      "--my-custom-themecolor",
      tempColor
    );
    document.documentElement.style.setProperty(
      "--my-custom-showFilter",
      showFilter
    );
    document.documentElement.style.setProperty(
      "--my-custom-filterDirection",
      filterDirection
    );
    document.documentElement.style.setProperty(
      "--my-custom-marginFilterRight",
      marginFilterRight
    );
    document.documentElement.style.setProperty(
      "--my-custom-marginFilterLeft",
      marginFilterLeft
    );

    document.documentElement.style.setProperty(
      "--my-custom-productDescription",
      productDescription
    );

    document.documentElement.style.setProperty(
      "--my-custom-displayPrices",
      displayPrices
    );
    
    const { id, bgColor, iconColor } = config.setting.buttonStyle;
    const searchBtn = document.querySelectorAll(`#${id}`);
    searchBtn.forEach((s) => {
      const btn = s.querySelector('.search-button');
      btn.style.backgroundColor = bgColor;
      const icon = btn.querySelector('svg');
      icon.style.color = iconColor;
    })
  }, []);


  return (
    <>
      <InstantSearch searchClient={searchClient} indexName={config.documents.products}>
        <SearchButton
          openModal={openModal}
          defaultPicture={config.setting.default_picture_url}
          custom={config.custom}
          documents={config.documents}
          searchPlaceholder={config.setting.searchPlaceholder}
        />

        {/* Comment for now */}
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            style={{ zIndex: 99999999999999999 }}
            className="fixed inset-0 overflow-y-auto"
            onClose={closeModal}
          >
            <div className="min-h-screen px-4 text-center">
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="unified-search-header-container inline-block fixed m-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl inset-0 	"
                style={config.setting.modal === 'Half' ? {transform:'scale(0.8)'}: {}}
                >
                  <Header
                    closeModal={closeModal}
                    customStyle={config.setting}
                    custom="none"
                  />
        
                  <SimpleBar id="customScrollBar">
                    <div className="unified-container">
                      <div className="unified-container__refinements">
                        <Refinements setting={config.setting} />
                      </div>

                      <section className="unified-container__hits">
                        <HitsHeader />
                            <CustomHits hits={Hits} defaultPicture={config.setting.default_picture_url} custom={config.custom}/>
                        <Footer />
                      </section>
              
                     
                      <div className='unified-container__mobile-filter' onClick={openMobileFilter}>
                          <button className="mobile-filters-button" >
                          <FontAwesomeIcon icon={faFilter} /> Filters <CustomCurrentRefinements />
                          
                          </button>
                      </div>
                    </div>  
                  </SimpleBar>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </InstantSearch>
    </>
  );
};

export default App;
