import React from "react";
import { Pagination } from "react-instantsearch-dom";

const footer = ({}) => {
  return (
    <>
      <footer className="unified-footer">
        <Pagination
          padding={2}
          showFirst={false}
          showLast={false}
          showPrevious={true}
          showNext={true}
          translations={{
            previous: 'Previous',
            next: 'Next',
          }}
        />
      </footer>
    </>
  );
};

export default footer;
