import React from "react";
import { Pagination } from "react-instantsearch-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft,faAngleRight,faAngleDoubleLeft,faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

const footer = ({}) => {
  return (
    <>
      <footer className="unified-footer">
        <Pagination
          padding={2}
          showFirst={true}
          showLast={true}
          showPrevious={true}
          showNext={true}
          translations={{
            first:(
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            ),
            last:(
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            ),
            previous: (
              <FontAwesomeIcon icon={faAngleLeft} />
            ),
            next: (
              <FontAwesomeIcon icon={faAngleRight} />
            ),
          }}
        />
      </footer>
    </>
  );
};

export default footer;
