import React, { useState } from "react";
import { connectAutoComplete, Stats, Snippet } from "react-instantsearch-dom";
import SearchIcon from "../../../unified-search/components/UI/search-icon";
import AutocompleteHits from "./autocomplete-hits";

const Autocomplete = ({ hits, currentRefinement, refine, openModal, defaultPicture, custom }) => {
  const [show, toggle] = useState(false);

  const onSearchClick = (e) => {
    e.preventDefault();
    openModal();
  };

  const OnBlur = () => {
    setTimeout( // set timeout to make click events trigger first before on blur
      () => toggle(false), 
      500
    );
  };

  let autocompletePanel = <></>;

  if (currentRefinement.length) {
    autocompletePanel = (
      <div className="aa-Panel">
        <div className="aa-PanelLayout aa-Panel--scrollable">
          <section className="aa-Source">
            <ul
              className="aa-List"
              style={{
                display: "flex",
                flexFlow: "wrap",
                justifyContent: "space-around",
              }}
            >
              {hits.slice(0, 6).map((hit) => (
                <li key={hit.id} className="aa-Item">
                  <a href={hit.url} className="aa-ItemLink">
                    <div className="aa-ItemContentBody">
                      <div className="aa-ItemContent">
                        <div className="aa-ItemIcon aa-ItemIcon--picture aa-ItemIcon--alignTop">
                          <img
                            src={hit.image ? hit.image : defaultPicture}
                            alt={hit.name}
                            width="20"
                            height="20"
                          />
                        </div>
                        <div className="aa-ItemContentBody">
                          <div className="aa-ItemContentDescription">
                            From <strong>{hit.brand}</strong> in{" "}
                            <strong>{hit.categories[0]}</strong>
                          </div>
                          <div
                            className="aa-ItemContentDescription"
                            style={{ color: "#000" }}
                          >
                            <strong>${hit.price.toLocaleString()}</strong>
                          </div>
                        </div>
                      </div>
                      <div className="aa-ItemContentTitle">
                        <Snippet hit={hit} attribute="name" tagName="mark" />
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </section>
        </div>
        <div className="autocomplete-footer">
          <div>
            <Stats
              translations={{
                stats(nbHits) {
                  return `See all matching results for "${currentRefinement}" (${nbHits.toLocaleString()})`;
                },
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="aa-Autocomplete"
        role="combobox"
        aria-expanded="false"
        aria-haspopup="listbox"
        aria-labelledby="autocomplete-2-label"
        onFocus={() => toggle(true)}
        onBlur={OnBlur}
      >
        <div className="aa-Form">
          <div className="aa-InputWrapper">
            <input
              className="aa-Input"
              aria-autocomplete="both"
              aria-labelledby="autocomplete-0-label"
              id="autocomplete-0-input"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              enterKeyHint="search"
              spellCheck="false"
              placeholder=""
              maxLength="512"
              type="search"
              value={currentRefinement}
              onChange={(event) => refine(event.currentTarget.value)}
              style={{outline: 'unset !important'}}
            />
          </div>
          <div className="aa-InputWrapperSuffix">
            <label className="aa-Label">
              { (custom == "osg") ? 
                <button className="aa-SubmitButton search-button search-button-osg" onClick={onSearchClick}>
                  <div class="search-button-icon">
                    <SearchIcon height="20" width="20"/>
                  </div>
                </button>
              :
                <button className="aa-SubmitButton" onClick={onSearchClick}>
                  <SearchIcon height="20" width="20" />
                </button>
              }
            </label>
          </div>
        </div>
      </div>

      {show && (
        <AutocompleteHits
          hits={hits}
          currentRefinement={currentRefinement}
          openModal={openModal}
          refine={refine}
          defaultPicture={defaultPicture}
          custom={custom}
        />
      )}
    </>
  );
};
export default connectAutoComplete(Autocomplete);
