import React from "react";
import { Highlight, Snippet } from "react-instantsearch-dom";

const Hit = ({ hit }) => {
  return (
    <>
      <article className="unified-hits">
        <a href={hit.product_url} className="unified-hits__inner">
          <div className="unified-hits__inner__image-container">
            <img src={hit.image} alt={hit.name} />
          </div>
          <div className="unified-hits__inner__body">
            <header className="unified-hits__inner__body__header">
              <h1 className="unified-hits__inner__body__header--title">
                <Highlight attribute="name" tagName="mark" hit={hit} />
              </h1>
            </header>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "-webkit-fill-available"
              }}
            >
              <p
                className="unified-hits__inner__body__description"
                style={{
                  display: "block",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                <Highlight attribute="sku" tagName="mark" hit={hit} />
              </p>
              <p className="unified-hits__inner__body__description unified-hits__inner__body--ellips">
                <Highlight attribute="description" tagName="mark" hit={hit} />
              </p>
              <footer>
                <span className="unified-hits__inner__body--currency">$</span>
                <span className="unified-hits__inner__body--price">
                  {hit.price.toLocaleString()}
                </span>
              </footer>
            </div>
          </div>
        </a>
      </article>
    </>
  );
};

export default Hit;
