export default function getBackendLink() {
  let tranzetaServer;
  //   if (window.location.href.includes("local")) {
  //     tranzetaServer = "http://localhost:5555";
  //   } else {
  //     tranzetaServer = "https://tranzetta-backend-dev.four13.co";
  //   }
  tranzetaServer = "https://tranzetta-backend-dev.four13.co";
  return tranzetaServer;
}
