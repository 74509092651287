import React, { useState } from "react";
import { connectHits } from "react-instantsearch-dom";
import Hit from "./hit";

const Hits = ({ hits }) => {
  // PUT THE IS MOBILE LOGIC HERE
  let [firstLoad, setFirstLoad] = useState(true);

  if(hits.length > 0 && firstLoad) {
      setFirstLoad(false);
  }
  
  return (
    <>
      <div className="ais-Hits">
        <table className="table table-striped table-bordered nowrap text-center" style={{ "width": "100%" }}>
          <thead className="k-custom-thead">
            <tr>
              <th className="col-md-6">Model Number</th>
              <th className="col-md-3">Brand</th>
              <th className="col-md-3">URL</th>
            </tr>
          </thead>
          <tbody className="k-custom-tbody">

            {
              firstLoad &&

              <tr>
                <td colSpan="3">Loading...</td>
              </tr>
            }

            { 
              (hits.length === 0 && !firstLoad) &&

              <tr>
                <td colSpan="3">No matching records found - Try backspacing until results show and choose the closest match.</td>
              </tr>
            }

            {hits.map((hit) => (
              <Hit key={hit.id} hit={hit} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default connectHits(Hits);
