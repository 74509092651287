import React from "react";
import { connectHits } from "react-instantsearch-dom";

const Hits = ({ hits, searchVal }) => {
    const path = window.location.hostname;
    let hitLists = (
        <>
          <div className="no-result">
            <div>
              <div className="no-result--title">
                No result for query "{searchVal}"
              </div>
            </div>
          </div>
        </>
      );
    
      if (hits.length) {
        hitLists = (
          <>
            <div className="aa-PanelLayout aa-Panel--scrollable" >
              <section className="aa-Source">
                  <ul
                    className="aa-List"
                    style={{
                      display: "flex",
                      flexFlow: "wrap",
                      width: 'auto'
                    }}
                  >
                    {hits.map((hit) => (
                      <>
                        <li key={hit.id} className="aa-Item" style={{ width: '100%' }}>
                            <a href={hit.url} style={{ textDecoration: 'none', padding: '10px 0' }}>{hit.name}</a>
                        </li>
                      </>
                    ))}
                  </ul>
              </section>
            </div>
          </>
        );
      }
    
      if (searchVal.length === 0) {
        return <></>;
      }
    
      return (
        <>
          <div className="aa-Panel">
            {hitLists}
          </div>
        </>
      );
};

export default connectHits(Hits);
