import $ from "jquery";

var searchHeader = "body";
//var searchHeader = findHeader().length > 0 ? findHeader()[0] : "body";

//Custom Check forButton Name that contains search
$.expr[":"].customcontains = function (a, i, m) {
  return $(a).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
};

//check for the element if it has attributes that contains search
function getAllAtributes(selector) {
  var cnt = 0;
  var elem = $(selector)[0];
  var elemClass = "";
  var elemId = "";
  var attrs = elem ? elem.attributes : false;
  if (attrs) {
    elemClass = attrs.class ? attrs.class.value : "";
    elemId = attrs.id ? attrs.id.value : "";
    var attrList = Object.entries(attrs);
    attrList.map((attr) => {
      if (
        attr[1].name.toLowerCase().includes("search") ||
        attr[1].value.toLowerCase().includes("search")
      )
        cnt++;
    });
  }
  return [cnt, elemClass, elemId];
}

//Find Header tag
function findHeader() {
  var cnt = [];
  var elem = document.querySelector("body").children;
  var childList = Object.entries(elem);
  childList.map((child) => {
    if (cnt.length === 0) {
      if (child[1].className.toString().toLowerCase().includes("header")) {
        cnt.push("." + child[1].className.split(" ")[0]);
      } else if (child[1].id.toLowerCase().includes("header")) {
        cnt.push("#" + child[1].id.split(" ")[0]);
      } else if (child[1].localName.toLowerCase().includes("header")) {
        cnt.push(child[1].localName);
      }
    }
  });
  return cnt;
}

// search for the input/button element on the header
function searchElements() {
  var headerInputElement = $(searchHeader + " form input:not(#search_query_cart)");
  var searchInputList = Object.values(headerInputElement);
  var algoliaContainer = $(
    "<div style='width:100%' class='algoliaSearchNode'></div>"
  );
  var cnt = 0;
  console.log(headerInputElement)
  // search for all input in the header tag
  searchInputList.map((input) => {
    $(input).addClass("algoliaElementCheck");
    if (
      getAllAtributes(".algoliaElementCheck")[0] > 0 &&
      $(input).parents("form").length > 0
    ) {
      cnt++;
      $(".algoliaElementCheck")
        .clone()
        .removeClass("algoliaElementCheck")
        .prependTo("body")
        .addClass("cloneSearch" + cnt)
        .css("display", "none");
      $(".algoliaElementCheck").wrap(
        algoliaContainer.attr("id", "searchbox" + cnt)
      );
    }
    $(input).removeClass("algoliaElementCheck");
  });

  // now search for  button.
  var headerButtonElement = $(searchHeader + " form button");
  var searchButtonList = Object.values(headerButtonElement);
  searchButtonList.map((input) => {
    $(input).addClass("algoliaElementCheck");
    if (
      (getAllAtributes(".algoliaElementCheck")[0] > 0 ||
        $(".algoliaElementCheck:customcontains('search')").length > 0) &&
      $(input).parents("form").length > 0
    ) {
      cnt++;
      $(".algoliaElementCheck")
        .clone()
        .prependTo("body")
        .removeClass("algoliaElementCheck")
        .addClass("cloneSearch" + cnt)
        .css("display", "none");
      $(".algoliaElementCheck").wrap(
        algoliaContainer.attr("id", "searchbox" + cnt)
      );
    }
    $(input).removeClass("algoliaElementCheck");
  });
}

export const CreateContainer = () => {
  searchElements();
};

export const AddAttributes = () => {
  if ($(".algoliaSearchNode").length > 0) {
    var searchNode = document.querySelectorAll(".algoliaSearchNode");
    var searchNodeList = Object.values(searchNode);
    searchNodeList.map((input) => {
      var num = input.id.slice(-1);
      var cloneElement = $(".cloneSearch" + num);
      var algoliaSearch = $("#" + input.id);
      // set the orig text/placeholder for search bar
      var origSearchText = cloneElement.text()
        ? cloneElement.text()
        : cloneElement.attr("placeholder")
        ? cloneElement.attr("placeholder")
        : "Search for products";
      // algoliaSearch.find($(".algoliaText")).text(origSearchText);

      // remove the form action
      algoliaSearch.parents("form").removeAttr("action");

      // Get attributes from orig and the key identifier and remove the dummy class
      algoliaSearch
        .find("button")
        .addClass(getAllAtributes(cloneElement)[1])
        .attr("id", getAllAtributes(cloneElement)[2])
        .removeClass("cloneSearch" + num);

      // if searchbox has siblings
      if (algoliaSearch.siblings().length > 0) {
        algoliaSearch.parent().css({ display: "flex", "flex-wrap": "nowrap" });
        algoliaSearch
          .siblings()
          .not("button")
          .removeAttr("type")
          .prop("disabled", true);
      }
      if (algoliaSearch.siblings("button").length > 0) {
        algoliaSearch
          .siblings("button")
          .removeAttr("type")
          .prop("disabled", true);
        algoliaSearch.siblings("button").children().css("display", "none");
        algoliaSearch.siblings().not("button").css("display", "none");
      }
      //if form have sibling
      if (algoliaSearch.parents("form").children().length > 1) {
        algoliaSearch
          .parents("form")
          .children()
          .not($("fieldset"))
          .css("display", "none");
      }
      cloneElement.remove();
    });
  } else {
    console.log("We do not found any search module in the store.");
  }
};

export const AppendAttribute = () => {
  if ($(".algoliaSearchNode").length > 0) {
      $('.custom-search').removeClass('search');
  }
};
