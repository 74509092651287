import React from "react";
import { Stats, Snippet, PoweredBy, Highlight } from "react-instantsearch-dom";

const AutocompleteHits = ({ hits, currentRefinement, openModal, refine, defaultPicture, custom }) => {
  const noResult = () => {
    refine("");
    openModal();
  };

  const getReplaceData = (customFields, custom) => {
    let customData = {};
    if(custom === "kimball") {
      const objArr = JSON.parse(customFields);
      customData = objArr.filter(i => { return i.name === "Replaces"; })[0];
    }
    return customData?.value ? customData : null;
  }

  const getSubstituteData = (customFields, custom) => {
    let customData = {};
    if(custom === "osg") {
      const objArr = JSON.parse(customFields);
      customData = objArr.filter(i => { return i.name === "Substitute Item"; })[0];
    }

    return customData?.value ? customData : null;
  }


  let hitLists = (
    <>
      <div className="no-result">
        <div>
          <div className="no-result--title">
            No result for query "{currentRefinement}"
          </div>
          <div onClick={noResult} className="no-result--see-all">
            <strong>See all Products</strong>
          </div>
        </div>
      </div>
    </>
  );

  if (hits.length) {
    hitLists = (
      <>
        <div className="aa-PanelLayout aa-Panel--scrollable" >
          <section className="aa-Source">
            <ul
              className="aa-List"
              style={{
                display: "flex",
                flexFlow: "wrap",
                float: "left",
                
                // justifyContent: "space-around",
                // width: '1000px'
              }}
            >
              {hits.slice(0, 6).map((hit) => (
                <>
                <li key={hit.id} className="aa-Item" style={{ width: '100%', borderBottom: 'solid 1px #dedede'}}>
                  <a href={hit.url} className="aa-ItemLink" style={{padding: '5px 5px 5px 5px'}}>
                    <div className="aa-ItemContentBody">
                      <div className="aa-ItemContent">
                        <div className="aa-ItemIcon aa-ItemIcon--picture aa-ItemIcon--alignTop">
                          <img
                            src={hit.image ? hit.image : defaultPicture}
                            alt={hit.name}
                            width="20"
                            height="20"
                          />
                        </div>
                        <div className="aa-ItemContentBody">
                        <div className="aa-ItemContentTitle">
                          <Highlight hit={hit} attribute="name" tagName="mark" />
                        </div>
                          <div className="aa-ItemContentDescription">
                              {
                                //custom for kimball view
                                getReplaceData(hit.customFields, custom) ? 
                                  (<>
                                    <strong>Replaces: </strong> {getReplaceData(hit.customFields, custom).value}
                                    <br />
                                  </>) : (<>{""}</>)
                              }
                              {
                                //custom for osg view
                                getSubstituteData(hit.customFields, custom) ? 
                                  (<>
                                    <strong>Alternatives: </strong> {getSubstituteData(hit.customFields, custom).value}
                                    <br />
                                  </>) : (<>{""}</>)
                              }
                              { hit.brand ? (
                                <>Brand: <strong>{hit.brand}</strong> <br /></>
                                ) :
                                (<>{""}</>)
                              }
                              { hit.categories.length ? (
                                <>Categories: <strong>{hit.categories[0]}</strong> <br /></>
                                ) :
                                (<>{""}</>)
                              }
                          </div>
                          <div
                            className="aa-ItemContentDescription"
                            style={{ color: "#000" , overflow: 'hidden'}}
                          >
                            <strong style={{ fontSize: '1.2rem'}}>${hit.price.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}</strong>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </a>
                </li>
                

                </>
              ))}
            </ul>
          </section>
        </div>
        <div className="autocomplete-footer">
          <div onClick={openModal} className="autocomplete-footer__stats" style={{fontWeight: 'normal'}}>
            <strong>
              <Stats
                translations={{
                  stats(nbHits) {
                    return `See all matching results for "${currentRefinement}" (${nbHits.toLocaleString()})`;
                  },
                }}
              />
            </strong>
          </div>
          {/* {<div className="autocomplete-footer__poweredby" >
            <PoweredBy
              translations={{
                searchBy: "Search by",
              }}
            />
          </div>} */}
        </div>
      </>
    );
  }

  if (currentRefinement.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className="aa-Panel">{hitLists}</div>
    </>
  );
};
export default AutocompleteHits;
