import React from "react";
import Autocomplete from "./autocomplete/autocomplete";

const searchButton = ({
  openModal,
  defaultPicture,
  custom
}) => {
  
  return (
    <>
      <div style={{ position: "relative", width: "100%"}}>
        <Autocomplete openModal={openModal} defaultPicture={defaultPicture} custom={custom} />
      </div>
    </>
  );
};

export default searchButton;
