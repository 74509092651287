import React from "react";

const SearchIcon = ({ width = 30, height = 30 }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 20 20"
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.67"
          transform="translate(1 1)"
        >
          <circle cx="7.11" cy="7.11" r="7.11" />
          <path d="M16 16l-3.87-3.87" />
        </g>
      </svg>
    </>
  );
};

export default SearchIcon;
