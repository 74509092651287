import React from "react";
import { connectStats } from "react-instantsearch-dom";

const Stats = ({ nbHits }) => {
  return (
    <>
      <div className="ais-Stats">
        <h1 className="ais-Stats-text">
          <span className="ais-Stats-mainText">
            See {nbHits.toLocaleString()} result(s)
          </span>
        </h1>
      </div>
    </>
  );
};

export default connectStats(Stats);
