import React, { useState } from "react";
import { typesenseSearchClient } from "../utils/typesenseSearchClient";
import { Hits, InstantSearch, SearchBox } from "react-instantsearch-dom";
import CustomHits from "./components/hits"
import CustomSearchBox from "./components/osg-custom-search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";


const App = ({ config }) => {
    const searchClient = typesenseSearchClient(config).searchClient;
    const [searchVal, setSearchVal] = useState("")
    const handleChange = (event) => {
        setSearchVal(event.target.value)
    }
    return (
        <>
             <InstantSearch searchClient={searchClient} indexName={config.index}>
                <div className="blog-search-wrapper">
                    {/* <CustomSearchBox handleChange={handleChange} searchVal={searchVal} /> */}
                    <SearchBox className="osgBlogSearch" translations={{ placeholder: 'Search Now' }} onChange={handleChange} />
                    <FontAwesomeIcon className="blog-search-icon" icon={faSearch}/>
                </div>
                <CustomHits hits={Hits} searchVal={searchVal} />
             </InstantSearch>
        </>
    );
};

export default App;