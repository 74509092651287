import { connectSearchBox } from 'react-instantsearch-dom';

const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (

  <div className="ais-SearchBox col-sm-12 col-md-3 align-items-right k-custom-search-box">
    <div className="ais-SearchBox-form">
      <input 
          className="ais-SearchBox-input k-custom-searchbox" 
          //autocomplete="off" 
          //autocorrect="off" 
          //autocapitalize="off" 
          placeholder="Search for models"
          //spellcheck="false" 
          //maxlength="512" 
          type="search"
          value={currentRefinement}
          onChange={event => refine(event.currentTarget.value)}
        />
    </div>
  </div>
  
);

const CustomSearchBox = connectSearchBox(SearchBox);

export default CustomSearchBox;