import React from "react";
import { SearchBox } from "react-instantsearch-dom";
import { CloseIcon } from "./UI/close-icon";
import CustomSearchBox from "./custom-searchbox";

const header = ({ closeModal, customStyle, custom }) => {
  return (
    <>
      <div className="unified-search-header">
        {custom === "brookaire" && <CustomSearchBox />}
        {custom === "none" && (
          <SearchBox
            className="unified-search-header__searchbox"
            translations={{
              placeholder: customStyle.searchPlaceholder
                ? customStyle.searchPlaceholder
                : "Search for product",
            }}
            submit={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 20 20"
              >
                <g
                  fill="none"
                  fillRule="evenodd"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.67"
                  transform="translate(1 1)"
                >
                  <circle cx="7.11" cy="7.11" r="7.11" />
                  <path d="M16 16l-3.87-3.87" />
                </g>
              </svg>
            }
          />
        )}
        <button
          className="unified-search-header__close-button"
          title="Press Esc to close"
          onClick={closeModal}
        >
          <CloseIcon />
        </button>
      </div>
    </>
  );
};

export default header;
