import { SearchBox, connectHits } from "react-instantsearch-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrosshairs, faGlobe, faUser, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import Hit from "./hit";
import Swal from "sweetalert2";
import { useStateContext } from "../context/StateContextProvider";
import { useEffect, useState } from "react";

const Search = ({ hits }) => {
    const {
        baseURl,
        isSearched,
        nationalData,
        searchSubmit,
        getRadiusSearch,
        getCustomerLocation,
        setIsLocalCollapsed,
    } = useStateContext();

    const [activeCustomers, setActiveCustomers] = useState([]);

    const salesman = hits?.filter((hit) => hit.customerType.toLowerCase() === 'salesman');
    const local = activeCustomers?.filter((hit) => hit.customerType.toLowerCase() === 'local');
    // const national = activeCustomers?.filter((hit) => hit.customerType.toLowerCase() === 'national');
    const distributor = local?.length + nationalData?.length;

    const [terms, setTerms] = useState({});

    useEffect(() => {
        const getCompanyTerms = async () => {
            const termRequests = hits.map((hit) => 
                fetch(`${baseURl}getB2BCompaniesByName?companyName=${hit.companyName}%26isIncludeExtraFields=1`)
                .then(async (res) => ({
                    data: await res.json(),
                    key: hit.companyName?.toLowerCase(),
                }))
            );

            const termResponse = await Promise.all(termRequests);
            setTerms(termResponse.reduce((prev, curr) => ({
                ...prev,
                [curr.key]: curr.data,
            }), {}));
        }

        getCompanyTerms();
    }, [hits]);

    useEffect(() => {
        const filterActiveCustomers = async () => {
            const activeCompanies = [];

            for (const hit of hits) {
                const term = terms[hit.companyName?.toLowerCase()];

                if (!term) continue;

                const existingCompanies = term.companies.filter(({ companyName }) => 
                    companyName.toLowerCase() === hit.companyName.toLowerCase()
                );
                
                existingCompanies.forEach(({ extraFields }) => {
                    const statement = extraFields?.find(({ fieldName }) => 
                        fieldName === 'Account Statement'
                    );

                    if (!statement) return;

                    const parsedStatement = statement && statement.fieldValue 
                        ? JSON.parse(statement.fieldValue) 
                        : {};

                    
                    const terms = (parsedStatement["Terms"] ?? "").toLowerCase();

                    if (!terms.includes('inactive') || !terms.includes('terminated')) {
                        activeCompanies.push(hit);
                    }
                });

            }
            
            setActiveCustomers(activeCompanies);
        }

        filterActiveCustomers();
    }, [hits, terms]);

    const result = `We found ${salesman.length} Sales ${salesman.length <= 1 ? "Representative" : "Representatives"} 
    and ${distributor} ${distributor <= 1 ? "Distributor" : "Distributors"}.`;

    const successCallback = (position) => {
        let lat = position.coords.latitude;
        let lng = position.coords.longitude;
        getCustomerLocation(lat, lng)

        const findDistSearch = document.querySelectorAll('.findDist-searchField');
        findDistSearch.forEach((search) => {
            const input = search.querySelector('input');
            if (input) {
                input.setAttribute('value', 'Your Location')
            }
        });
        
    };
      
    const errorCallback = () => {
        Swal.fire({
            icon: 'error',
            text: 'Please enable location access in your browser settings to use this feature.'
        })
    };

    const customerLocation = () => {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }

    return (
        <div className="findDist-modal">
            <div className="findDist-search">
                <h2>Find A Branch</h2>
                <div className="findDist-searchField">
                    <SearchBox translations={{ placeholder: 'Search by Zip Code or City, State' }} onSubmit={searchSubmit} value='Your Location' />
                    <button className="findDist-loc" onClick={customerLocation}>
                        <FontAwesomeIcon icon={faCrosshairs} />
                    </button>
                </div>
                <select className="findDist-radius form-select" onChange={getRadiusSearch} defaultValue={100}>
                  <option value="5">within 5 miles</option>
                  <option value="10">within 10 miles</option>
                  <option value="25">within 25 miles</option>
                  <option value="50">within 50 miles</option>
                  <option value="100">within 100 miles</option>
                </select>
                <div className="findDist-info">
                    <div className="left-half">
                        <ul>
                            <li><FontAwesomeIcon icon={faUser} /> <span>Sales Man</span></li>
                            <li><FontAwesomeIcon icon={faWarehouse} /> <span>Local Distributor</span></li>
                            <li><FontAwesomeIcon icon={faGlobe} /> <span>National Distributor</span></li>
                        </ul>
                    </div>
                    <div className="right-half">
                          <a href='/contact-us/#contact-map'>Browse All Locations</a>
                    </div>
                </div>
            </div>
            { isSearched &&
                <>
                    <div className="search-result"><p>{result}</p></div>
                    <Hit salesMan={salesman} local={local} nationalCount={nationalData.length} /> 
                </>
            }
        </div>
    )
}

export default connectHits(Search);