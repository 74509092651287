import { faEnvelope, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const SalesMan = ({salesMan}) => {
    return (
        <>
            <FontAwesomeIcon className="card-icon sales-man--icon" icon={faUser} />
            <h3 className="sales-man--name">{salesMan.companyName}</h3>
            { salesMan.phone && (
                    <p className="card-item sales-man--phone">
                        <FontAwesomeIcon icon={faPhone} />
                        <span>{salesMan.phone}</span> 
                    </p>
                )
            }
            { salesMan.email && (
                    <p className="card-item sales-man--mail">
                        <FontAwesomeIcon icon={faEnvelope} />
                        <a href={`mailto:${salesMan.email}`}>{salesMan.email}</a> 
                    </p>
                )
            }
        </>
    )
}

export default SalesMan;