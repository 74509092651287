import React from "react";

const Hit = ({ hit }) => {
  return (
    <>
      <tr key={hit.objectID}>
        <td>
          {hit.name}
        </td>
        <td>
          {hit.brand}
        </td>
        <td>
          <a className="model-pdf" href={hit.url} target="_blank">Download PDF</a>
        </td>
      </tr>
    </> 
  );
};

export default Hit;
