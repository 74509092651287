import BigNumber from "bignumber.js";
import {
    LAMPORTS_PER_SOL,
  } from '@solana/web3.js'

export default function totalAmount(price) {
 //the Total Amount
let val = price ? Number(price) : 1; //value in SOL
let amount = new BigNumber(0);
amount = amount.plus(val);

return amount.multipliedBy(LAMPORTS_PER_SOL).toNumber(); //in SOL
}