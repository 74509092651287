import React from "react";
import Autocomplete from "./autocomplete/autocomplete";

const searchButton = ({
  openModal
}) => {
  
  return (
    <>
      <div style={{ position: "relative" }}>
        <Autocomplete openModal={openModal} />
      </div>
    </>
  );
};

export default searchButton;
