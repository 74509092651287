import getBackend from "./getBackendLink";

export default async function getProduct(id) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var tranzetaServer = getBackend();

  var raw = JSON.stringify({
    productId: id,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${tranzetaServer}/modules/bigcommerce/NFT/api/get-product`,
      requestOptions
    );
    const data = await res.json();

    return data;
  } catch (error) {
    console.log("error", error);
  }
}
