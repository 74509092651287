import React from "react";
import { connectHits } from "react-instantsearch-dom";

const formatToUSD = new Intl.NumberFormat(navigator.language, {
  style: 'currency',
  currency: 'USD',
}).format

const Hits = ({ hits, visible, setSearchValue, defaultImage }) => {
  if (!visible) return null;

  let hitList = (
    <div className="no-result">
      <div>
        <div className="no-result--title">
          No results
        </div>
      </div>
    </div>
  );

  if (hits.length) {
    hitList = (
      <div className="aa-PanelLayout aa-Panel--scrollable">
        <section className="aa-Source">
          <ul className="aa-List">
            
            {hits.map((hit) => {
              const customFields = JSON.parse(hit.customFields).reduce((a, f) => ({...a, [f.name]: f.value }), {})
              const productName = customFields['Product Name']
              const stockLeft = JSON.parse(hit.customFields).reduce((a, f) => f.name.startsWith('WarehouseName') ? (a + +f.value) : a, 0)
              const isDiscontinued = customFields.ProductLifeCycleState === 'Discontinued'
              const isPhaseOut = customFields.ProductLifeCycleState === 'Phase-Out'
              const stockToDisplay = isDiscontinued ? 0 : stockLeft

              return (
                <li key={hit.id} className="aa-Item cartQuickOrderResults-item" onMouseDown={(e) => {
                  if (isDiscontinued) {
                    e.preventDefault()
                    e.stopPropagation()
                    return false
                  }

                  setSearchValue(hit.sku)
                }}>
                  <div className="aa-ItemContentBody cartQuickOrderResults-grid">
                    <div className="aa-ItemIcon aa-ItemIcon--picture aa-ItemIcon--alignTop cartQuickOrderResults-image">
                      <img
                        src={hit.image ? hit.image : defaultImage}
                        alt={hit.name}
                        width="20"
                        height="20"
                      />
                    </div>
                    <div className="cartQuickOrderResults-productName">{productName}</div>
                    <div className="cartQuickOrderResults-attrs">
                      <div>EDP: <strong>{hit.sku}</strong></div>
                      <div>Series: <strong>{hit.categories[0]}</strong></div>
                      <div className={stockToDisplay > 0 ? 'cartQuickOrderResults-inStock' : 'cartQuickOrderResults-noStock'}>
                        {isDiscontinued ? 'Discontinued' : `${stockToDisplay} in stock ${isPhaseOut ? '(While Supplies Last)' : ''}`}
                      </div>
                    </div>
                    <div className="cartQuickOrderResults-price">
                      {formatToUSD(hit.price)}
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </section>
      </div>
    );
  }

  return (
    <div className="aa-Panel">
      {hitList}
    </div>
  );
};

export default connectHits(Hits);
