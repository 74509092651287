import { createContext, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";

export const StateContext = createContext();

export default function StateContextProvider({children, props}) {
    const host = window.location.host;
    let coordsLat = host.includes('usa') || host.includes('localhost') ? 41.9202827 : 43.3255;
    let coordsLng = host.includes('usa') || host.includes('localhost') ? -88.29970639999999 : 79.7990;
    const [searchCoord, setSearchCoord] = useState({ lat: coordsLat, lng: coordsLng, radius: 100 });
    const [searchVal, setSearchVal] = useState('')
    const [isSearched, setIsSearched] = useState(false)
    const [baseURl, setBaseUrl] = useState('')
    const [isLocalCollapsed, setIsLocalCollapsed] = useState(true);
    const [isNationalCollapsed, setIsNationalCollapsed] = useState(true);
    const [nationalData, setNationalData] = useState([]);

    const accordionLocal = () => {
      setIsLocalCollapsed(isLocalCollapsed => !isLocalCollapsed);
    }
  
    const accordionNational = () => {
      setIsNationalCollapsed(isNationalCollapsed => !isNationalCollapsed);
    }

    useEffect(() => {
      const fetchData = async () => {
        const response = await fetch(
          `https://${props.server.host}/collections/${props.index}/documents/search?q=national&query_by=customerType`, 
          {
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              'x-typesense-api-key': props.server.key,
            }
          }
        );
  
        const data = await response.json();
        if(data) {
          setNationalData(data.hits);
          setIsNationalCollapsed(false);
          // console.log("national data hits", data.hits.length)
        }
      }
  
      fetchData();
    }, [])

    useEffect(() => {
      if (host.includes("localhost") || host.includes("dev")){
        setBaseUrl("https://tranzetta-v2-backend-dev.server.four13.dev/api/tranzetta/OSG-DEV/")
        
      } else if (host.includes("uat")) {
        setBaseUrl("https://tranzetta-v2-backend-dev.server.four13.dev/api/tranzetta/71f05f4b-d633-461f-b196-7101f4c79c03/")
      } else {
          // prod tranzetta
      }
      return baseURl
    }, [])

    useEffect(() => {
        if(searchVal === '') {
            setIsSearched(false)
        }
        const searchField = document.querySelector(".findDist-searchField");
        const input = searchField.querySelector("input");
        input.addEventListener('input', () => {
          if(input.value === '' || input.value.length < 2) {
            setIsSearched(false)
          }
        })
        input.addEventListener('keydown', (event) => {
            const key = event.key;
            if (key === "Backspace" || key === "Delete") {
                setIsSearched(false)
            }
        })
    }, [])
    
    async function geocoding(address) {
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${props.key}`;
  
        try {
          const response = await fetch(geocodeUrl);
        
          if (!response.ok) {
            throw new Error(`Failed to fetch data. Status: ${response.status}`);
          }
        
          const data = await response.json();
          if (data && data.results && data.results[0]) {
            setIsSearched(true)
            setSearchCoord((prevState) => {
              return {
                ...prevState,
                lat: data.results[0]?.geometry?.location?.lat || null,
                lng: data.results[0]?.geometry?.location?.lng || null,
              };
            });
          } else {
            console.log(data)
            Swal.fire({
              icon: 'error',
              text: 'No location found. Please enter a valid location.'
            })
          }
        } catch (error) {
          console.log(error)
          setIsSearched(false)
          if(!searchVal) {
            Swal.fire({
              icon: 'error',
              text: 'Location cannot be empty. Please try again.'
            })
          } else {
            Swal.fire({
              icon: 'error',
              text: "An error occured while trying to fetch data. Please try again."
            })
          }
        }
    }
  
    const getRadiusSearch = event => {
        let radiusValue = parseFloat(event.target.value);
        setSearchCoord((prevState) => {
            return {
                ...prevState,
                radius: radiusValue
            }
        })
    }

    const searchSubmit = (event) => {
        event.preventDefault();
        const target = event.target.querySelector('input')
  
        if(event.target.value !== '') {
          setSearchVal(event.target.value)
          geocoding(target.value)
        }
    }
  
    const getCustomerLocation = (lat, lng) => {
      if(lat && lng) {
        setIsSearched(true)
        setSearchCoord((prevState) => {
          return {
              ...prevState,
              lat,
              lng
          }
        })
      }
    }

    function degreesToRadians(degrees) {
        return degrees * Math.PI / 180;
      }
      
    function distanceInKM(lat2, lng2) {
      let lat1 = searchCoord.lat;
      let lng1 = searchCoord.lng;
      // let earthRadiusKm = 6371;
      let earthRadiusMiles = 3959;
    
      let dLat = degreesToRadians(lat2-lat1);
      let dLon = degreesToRadians(lng2-lng1);
    
      lat1 = degreesToRadians(lat1);
      lat2 = degreesToRadians(lat2);
    
      let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      return parseFloat(earthRadiusMiles * c).toFixed(2);
    }

    return (
        <StateContext.Provider value={{
            searchCoord,
            searchVal,
            isSearched,
            baseURl,
            geocoding,
            getRadiusSearch,
            searchSubmit,
            getCustomerLocation,
            distanceInKM,
            isLocalCollapsed,
            isNationalCollapsed,
            accordionLocal,
            accordionNational,
            setIsLocalCollapsed,
            setIsNationalCollapsed,
            nationalData, 
            setNationalData,
        }}>
            {children}
        </StateContext.Provider>
      )
    }

export const useStateContext = () => useContext(StateContext)