import React, { useState } from "react";
import { connectSearchBox } from "react-instantsearch-dom";

const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => {
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [depth, setDepth] = useState("");
  const [search, setSearch] = useState("");

  const mergeInputs = (x, event) => {
    let computedInputs = ``;

    if (x === "w") {
      setWidth(event.currentTarget.value);
      computedInputs = `${event.currentTarget.value}${length}${depth}`;
    } else if (x === "l") {
      setLength(event.currentTarget.value);
      computedInputs = `${width}${event.currentTarget.value}${depth}`;
    } else {
      setDepth(event.currentTarget.value);
      computedInputs = `${width}${length}${event.currentTarget.value}`;
    }
    // refine(computedInputs);
  };

  const INPUTS = [
    <input
      key="1"
      type="search"
      placeholder="Width"
      value={width}
      style={{
        padding: "12px 20px",
        margin: "8px 0",
        display: "inline-block",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
      onChange={(e) => mergeInputs("w", e)}
    />,
    <input
      key="2"
      type="search"
      placeholder="length"
      value={length}
      style={{
        padding: "12px 20px",
        margin: "8px 0",
        display: "inline-block",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
      onChange={(e) => mergeInputs("l", e)}
    />,
    <input
      key="3"
      type="search"
      placeholder="depth"
      value={depth}
      style={{
        padding: "12px 20px",
        margin: "8px 0",
        display: "inline-block",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
      onChange={(e) => mergeInputs("d", e)}
    />,
  ];

  const makeDivisibleByTen = (d) => {
    let x = +d;
    while (true) {
      if (x % 10 === 0) {
        break;
      } else {
        x++;
      }
      if (x === 100) {
        break;
      }
    }
    return x;
  };

  const onSearch = (e) => {
    e.preventDefault();
    const r = /(?: *(\d+(?:,\d+)?) *(?:x|$))/g;

    const array = [...search.matchAll(r)];

    if (array.length === 3) {
      const w = array[0]["1"];
      const l = array[1]["1"];
      const d = array[2]["1"];

      const computedInputs = `${makeDivisibleByTen(w)}${makeDivisibleByTen(
        l
      )}${d}`;
      refine(computedInputs);
    } else {
      refine(search);
    }
  };

  return (
    <form
      noValidate
      action=""
      role="search"
      style={{ display: "flex", gap: "12px" }}
    >
      <input
        type="search"
        value={search}
        onChange={(e) => {
          setSearch(e.currentTarget.value);
        }}
        style={{
          padding: "12px 20px",
          margin: "8px 0",
          display: "inline-block",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      />

      <button
        style={{
          backgroundColor: "#4CAF50",
          border: "none",
          color: "white",
          padding: "15px 32px",
          textAlign: "center",
          textDecoration: "none",
          display: "inline-block",
          fontSize: "16px",
          margin: "8px 0",
          borderRadius: "4px",
        }}
        onClick={(e) => onSearch(e)}
      >
        Search
      </button>
      {isSearchStalled ? "My search is stalled" : ""}
    </form>
  );
};

const CustomSearchBox = connectSearchBox(SearchBox);

export default CustomSearchBox;
