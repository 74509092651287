import { connectSearchBox } from 'react-instantsearch-dom';

const SearchBox = ({ currentRefinement, setVisible, setSearchValue }) => {
  return (
    <div className="form-field">
      <input
        className="form-input"
        placeholder="Search by keyword, brand or SKU"
        type="search"
        id="search_query_cart"
        value={currentRefinement}
        onBlur={() => { setVisible(false) }}
        onFocus={(e) => { setVisible(e.currentTarget.value.length > 0 ? true : false) }}
        onInput={(e) => { setVisible(e.currentTarget.value.length > 0 ? true : false) }}
        onChange={(e) => { setSearchValue(e.currentTarget.value) }}
      />
    </div>
  )
};

const CustomSearchBox = connectSearchBox(SearchBox);

export default CustomSearchBox;
