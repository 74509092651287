import React from "react";
// import logo from "./logo.svg";
// import "./App.css";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  GlowWalletAdapter,
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import MyWallet from "./components/wallet";
import CreateAttribute from "./components/createAttribute";
import Accordion from "./components/accordion";
import GetCustomAttr from "./components/getCustomAttr";
import GetDetails from "./components/createDetails";
import creatorImage from "./svg/creator.svg"
import calendarImage from "./svg/calendar.svg"
// import ProductMetaData from "../getMetaData";

require('@solana/wallet-adapter-react-ui/styles.css');

const App = ({productData}) => {
    
  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
  const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint
  const endpoint = React.useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
  // Only the wallets you configure here will be compiled into your application
  const wallets = React.useMemo(
    () => [
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
    ],
    [network]
  );
  
  console.log('productData', productData)
  const birthday = new Date(productData.products.date_created)
  return (
    <div className="App">
      
      <div className="App-header NFT-container productView">
          <div className="NFT-details">
            <div className="NFT-img"><img src={productData.products.primary_image.url_standard}/></div>
            <div className="NFT-info">
              <div className="NFT-name">{productData.products.name}</div>
              <div className="NFT-price"><b>(SOL)</b> {GetCustomAttr(productData.products.custom_fields,'Price in SOL')}</div>
              <div className="NFT-sku"><b>SKU:</b> <p className="sku">{productData.products.sku}</p></div>
              <div className="creator">
                <div className="addresses">Owner Address:<p><a
                target="_blank" href={`https://explorer.solana.com/address/${GetCustomAttr(productData.products.custom_fields,'Owner Address')}?cluster=devnet`}>
                  {GetCustomAttr(productData.products.custom_fields,'Owner Address')}</a></p></div>
              </div>
              <div className="creator">
                <div className="addresses">Token Program:<p>
                <a
                target="_blank" href={`https://explorer.solana.com/address/${GetCustomAttr(productData.products.custom_fields,'Token Program')}?cluster=devnet`}>
                  {GetCustomAttr(productData.products.custom_fields,'Token Program')}</a></p></div>
              </div>

              <br/>
              <div className="creator">
                <img src={creatorImage} />
                <div className="creatorText">Creators:<p>1</p></div>
              </div>
              <div className="creator">
                <img src={calendarImage} />
                <div className="creatorText">Birthday:<p>{birthday.toDateString()}</p></div>
              </div>
              {/* <div className="NFT-attr"> <CreateAttribute attr={GetCustomAttr(productData.products.custom_fields,'Attributes')}/> </div> */}
              {/* <div className="NFT-price NFT-text-Owner"><b>Owner Address:</b> {GetCustomAttr(productData.products.custom_fields,'Owner Address')}</div> */}
              <div className="NFT-btn">
                <ConnectionProvider endpoint={endpoint}>
                  <WalletProvider wallets={wallets}>
                    <div className="">
                      <header className="">
                        <MyWallet productData={productData}/>
                      </header>
                    </div>
                  </WalletProvider>
              </ConnectionProvider>
              </div>
            </div>
          </div>
          <div className="accordion">
          <Accordion productData={productData}/>
          </div>
          {/* <div className="NFT-desc">
            <div className="NFT-desc-header"> <b>ABOUT THIS NFT:</b></div>
            <div className="NFT-desc-text"> {productData.products.description ? productData.products.description : 'Description is not available on this NFT.'}</div>
          </div> */}
      </div>
  </div>
  );
}

export default App;
