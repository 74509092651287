import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connectSearchBox } from 'react-instantsearch-dom';

const SearchBox = ({ searchVal, handleChange }) => (

  <div className="ais-SearchBox col-sm-12 col-md-3 align-items-right osgBlogSearch">
    <div className="ais-SearchBox-form">
      <input 
          className="ais-SearchBox-input"
          //autocomplete="off" 
          //autocorrect="off" 
          //autocapitalize="off" 
          placeholder="Search Now"
          //spellcheck="false" 
          //maxlength="512" 
          type="text"
          value={searchVal}
          onChange={handleChange}
        />
        <FontAwesomeIcon className="blog-search-icon" icon={faSearch}/>
    </div>
  </div>
  
);

const CustomSearchBox = connectSearchBox(SearchBox);

export default CustomSearchBox;