import React from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";

import { sendTransactionWithRetry } from "../util/connection";
import { sendRedeemBid } from "../services/claim";

import {
  getAuctionView
} from "../services/auctionView";
import { setupPlaceBid } from "../services/buy";

const MyCheckout = () => {
  const { connection } = useConnection();
  const wallet = useWallet();

  const claimButton = async () => {
    // const auctionPubkey = "5VhsBH7X6ocbinbQs6AApwEyTZQ5CTz2q9mWqWYKHPxx";
    // const auctionPubkey = "8eDofNzmaEWRptdTWi76b2ijBKxat2YnwDfkgCXPf82Y";
    const auctionPubkey = "2AT75nMzPsqDYa8FNiRLfh5usSdNLMm972bgcM9sGbwF";

    const auctionView = await getAuctionView(auctionPubkey, connection);
    try {
      await sendRedeemBid(connection, wallet, auctionView);
    } catch (error) {
      console.log("🚀 ~ file: buy.jsx ~ line 366 ~ buyButton ~ error", error);
    }
  };

  const buyButton = async () => {
    // const auctionPubkey = "2AT75nMzPsqDYa8FNiRLfh5usSdNLMm972bgcM9sGbwF";
    const auctionPubkey = "Ds4XwM2fpX6ejswnRt9ensF6Njv33RbhnkeNvtAoLp3R";
    const AMOUNT = 5000000000;

    const auctionView = await getAuctionView(auctionPubkey, connection);
    const commitment = "finalized";

    let signers = [];
    let instructions = [];

    await setupPlaceBid(
      connection,
      wallet,
      wallet.publicKey,
      auctionView,
      AMOUNT,
      instructions,
      signers
    );

    const { txid } = await sendTransactionWithRetry(
      connection,
      wallet,
      instructions[0],
      signers[0],
      commitment
    );

    if (commitment) {
      await connection.confirmTransaction(txid, commitment);
    }
  };

  return (
    <>
      <button
        onClick={buyButton}
        className="buy-btn wallet-adapter-button wallet-adapter-button-trigger"
      >
        Buy Now
      </button>
      <button
        onClick={claimButton}
        className="buy-btn wallet-adapter-button wallet-adapter-button-trigger"
      >
        Claim Now
      </button>
    </>
  );
};

export default MyCheckout;
