import {
  AccountLayout,
  MintLayout,
  Token,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import {
  Keypair,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
} from "@solana/web3.js";

import { programs } from "@metaplex/js";

import { toPublicKey, findProgramAddress } from "../util/util";

import {
  sendTransactionsWithManualRetry,
  sendTransactionWithRetry,
} from "../util/connection";

const {
  metaplex: { RedeemBid, ClaimBid },
  metadata: {},
  auction: {},
  vault: {},
} = programs;

export const METADATA_PREFIX = "metadata";
export const METAPLEX_PREFIX = "metaplex";
export const AUCTION_PREFIX = "auction";
export const EXTENDED = "extended";

export async function sendRedeemBid(connection, wallet, auctionView) {
  const signers = [];
  const instructions = [];
  const safetyDeposits = auctionView.safetyDepositBoxes[0];
  const safetyDeposit = {
    pubkey: safetyDeposits.pubkey.toBase58(),
    account: safetyDeposits.info,
    info: safetyDeposits.data,
  };

  const accountRentExempt = await connection.getMinimumBalanceForRentExemption(
    AccountLayout.span
  );

  const mintRentExempt = await connection.getMinimumBalanceForRentExemption(
    MintLayout.span
  );

  await setupRedeemInstructions(
    auctionView,
    accountRentExempt,
    wallet,
    safetyDeposit,
    signers,
    instructions
  );

  // const claimSigners = [];
  // const claimInstructions = [];
  // instructions.push(claimInstructions);
  // signers.push(claimSigners);
  // console.log("Claimed");
  // const cb = await new ClaimBid(
  //   {},
  //   {
  //     acceptPayment: auctionView.auctionManager.acceptPayment,
  //     bidder: wallet.publicKey.toBase58(),
  //     bidderPotToken: auctionView.myBidderPot.data.bidderPot,
  //     vault: auctionView.vault.pubkey,
  //     tokenMint: auctionView.auction.info.tokenMint,
  //   }
  // );

  // claimInstructions.push(cb.instructions[0]);

  // await sendTransactionsWithManualRetry(
  //   connection,
  //   wallet,
  //   instructions,
  //   signers
  // );
  const commitment = "finalized";

  const { txid } = await sendTransactionWithRetry(
    connection,
    wallet,
    instructions[0],
    signers[0],
    commitment
  );

  if (commitment) {
    await connection.confirmTransaction(txid, commitment);
  }
}

async function setupRedeemInstructions(
  auctionView,
  accountRentExempt,
  wallet,
  safetyDeposit,
  signers,
  instructions
) {
  const winningPrizeSigner = [];
  const winningPrizeInstructions = [];

  signers.push(winningPrizeSigner);
  instructions.push(winningPrizeInstructions);

  let newTokenAccount = createTokenAccount(
    winningPrizeInstructions,
    wallet.publicKey,
    accountRentExempt,
    toPublicKey(safetyDeposit.info.tokenMint),
    wallet.publicKey,
    winningPrizeSigner
  ).toBase58();

  const dd = await new RedeemBid(
    { feePayer: wallet.publicKey },
    {
      auctionManager: auctionView.auctionManager.pubkey,
      safetyDepositTokenStore: toPublicKey(safetyDeposit.info.store),
      destination: toPublicKey(newTokenAccount),
      bidRedemption: auctionView.myBidRedemptionTicket.pubkey,
      safetyDeposit: toPublicKey(safetyDeposit.pubkey),
      vault: toPublicKey(auctionView.auctionManager.vault),
      fractionMint: toPublicKey(auctionView.vault.info.fractionMint),
      auction: auctionView.auction.pubkey,
      bidderMeta: auctionView.bidderMetadata.pubkey,
      bidder: wallet.publicKey,
      isPrintingType: false,
      store: auctionView.store.pubkey,
      transferAuthority: auctionView.transferAuthority.pubkey,
      safetyDepositConfig: auctionView.safetyDepositConfig.pubkey,
      auctionExtended: auctionView.auctionExtended.pubkey,
    }
  );

  winningPrizeInstructions.push(dd.instructions[0]);

  const metadata = await getMetadata(safetyDeposit.info.tokenMint);

  await updatePrimarySaleHappenedViaToken(
    metadata,
    wallet.publicKey.toBase58(),
    newTokenAccount,
    winningPrizeInstructions
  );

  console.log("🚀 ~ file: claim.js ~ line 105 ~ instructions", instructions);
}

export const createUninitializedAccount = (
  instructions,
  payer,
  amount,
  signers
) => {
  const account = Keypair.generate();

  instructions.push(
    SystemProgram.createAccount({
      fromPubkey: payer,
      newAccountPubkey: account.publicKey,
      lamports: amount,
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID,
    })
  );

  signers.push(account);

  return account.publicKey;
};

export const createTokenAccount = (
  instructions,
  payer,
  accountRentExempt,
  mint,
  owner,
  signers
) => {
  const account = createUninitializedAccount(
    instructions,
    payer,
    accountRentExempt,
    signers
  );

  instructions.push(
    Token.createInitAccountInstruction(TOKEN_PROGRAM_ID, mint, account, owner)
  );

  return account;
};

export async function getMetadata(tokenMint) {
  const PROGRAM_IDS = programIds();

  return (
    await findProgramAddress(
      [
        Buffer.from(METADATA_PREFIX),
        toPublicKey(PROGRAM_IDS.metadata).toBuffer(),
        toPublicKey(tokenMint).toBuffer(),
      ],
      toPublicKey(PROGRAM_IDS.metadata)
    )
  )[0];
}

export const programIds = () => {
  return {
    metadata: "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s",
    vault: "vau1zxA2LbssAUEF7Gpw91zMM1LvXrvpzJtmZ58rPsn",
    metaplex: "p1exdMJcjVao65QdewkaZRUnU6VPSXhus9n2GzWfh98",
    auction: "auctxRXPeJoc4817jDhf4HbjnhEcr1cCXenosMhK5R8",
  };
};

export async function updatePrimarySaleHappenedViaToken(
  metadata,
  owner,
  tokenAccount,
  instructions
) {
  const metadataProgramId = programIds().metadata;

  const data = Buffer.from([4]);

  const keys = [
    {
      pubkey: toPublicKey(metadata),
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: toPublicKey(owner),
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: toPublicKey(tokenAccount),
      isSigner: false,
      isWritable: false,
    },
  ];
  instructions.push(
    new TransactionInstruction({
      keys,
      programId: toPublicKey(metadataProgramId),
      data,
    })
  );
}

export async function getSafetyDepositConfig(auctionManager, safetyDeposit) {
  return (
    await findProgramAddress(
      [
        Buffer.from(METAPLEX_PREFIX),
        toPublicKey(programIds().metaplex).toBuffer(),
        toPublicKey(auctionManager).toBuffer(),
        toPublicKey(safetyDeposit).toBuffer(),
      ],
      toPublicKey(programIds().metaplex)
    )
  )[0];
}

export async function getAuctionExtended({ auctionProgramId, resource }) {
  return (
    await findProgramAddress(
      [
        Buffer.from(AUCTION_PREFIX),
        toPublicKey(auctionProgramId).toBuffer(),
        toPublicKey(resource).toBuffer(),
        Buffer.from(EXTENDED),
      ],
      toPublicKey(auctionProgramId)
    )
  )[0];
}
