import { useMemo } from "react";
import algoliasearch from "algoliasearch/lite";

export function useSearchClient(config) {
  const appId = config.appID;
  const apiKey = config.searchKey;
  const searchClient = useMemo(() => {
    const client = algoliasearch(appId, apiKey);

    return {
      ...client,
    };
  }, [appId, apiKey]);

  return searchClient;
}
