import React, {useState, useEffect} from "react";
import { Stats, Snippet, PoweredBy, Highlight, Index, Configure, Hits, SearchBox} from "react-instantsearch-dom";

const DisplayHits = ({ hits, currentRefinement, openModal, defaultPicture }) => {
  const noResult = () => {
    refine("");
    openModal();
  };

  const [isShow, showCategoriesProducts] = useState(false);
  const [isShowProducts, showProducts] = useState([]);

  const searchProducts = (categoryID, type) => {
    let filterProducts;
    showCategoriesProducts(true);
    showProducts([]);

    const filteredCategories = hits.filter((items) => items.index.includes(type));
    const filteredHits = filteredCategories[0].hits;
    
    for(let x = 0; x < filteredHits.length; x++){
      const findCategories = JSON.parse(filteredHits[x].productsData);
      filterProducts = findCategories.filter((item) => item.category_id === categoryID);
      if(filterProducts.length > 0) break;
    }

    showProducts(filterProducts);
  }


  const getSubstituteData = (customFields) => {
    let customData = {};
    if(customFields.length > 1 || customFields != "") {
      if(Array.isArray(customFields)){
        customFields.filter(i => { return i.name === "Substitute Item"; })[0];
      }else{
         const objArr = JSON.parse(customFields);
        if(objArr){
          customData = objArr.filter(i => { return i.name === "Substitute Item"; })[0];
        }
      }
     
    }

    return customData?.value ? customData : null;
  }

  const getCatgoriesUrl = (categories , type) => {
    const catData = JSON.parse(categories);
    let responseData;

    if(type) responseData = catData.filter((item) => item.name === type);

    return responseData ? responseData : catData;
  }

  const OnBlur = () => {
    showCategoriesProducts(false);
  };

  let countHits = 0;
  for(let x = 0; x < hits.length; x++){
    countHits += hits[x]?.hits?.length;
  }

  let hitLists = (
    <>
      <div className="no-result">
        <div>
          <div className="no-result--title">
            No result for query "{currentRefinement}"
          </div>
          <div onClick={noResult} className="no-result--see-all">
            <strong>See all Products</strong>
          </div>
        </div>
      </div>
    </>
  );

  if (hits.length) {
    hitLists = (
      <>
        <div className="aa-PanelLayout aa-Panel--scrollable aa-PanelLayout-custom" onMouseLeave={OnBlur}>
          <section className="aa-Source">
            <div className="aa-OtherResults">
              <div className="aa-OtherResultsPanel">
                {hits.map((items) => (
                    <>  
                        {items.hits && (items.index.includes("blogs") || items.index.includes("pages") || items.index.includes("series") || items.index.includes("categories")) && items.hits.length > 0 ? (
                            <>
                                <h4 className="custom-searchHeader">{items.index.includes("blogs") ? "Blogs" : items.index.includes("pages") ? "Pages" : items.index.includes("categories") ? "Categories" : items.index.includes("series") ? "Series" : ""}</h4>
                                <div style={{opacity: '0.5', borderBottom: '1px solid #00559D'}}></div>

                                {items.index.includes("categories") || items.index.includes("series")? (
                                  <> 
                                    <ul style={{paddingTop: '20px'}}>
                                      {items.hits.map((hit) => (
                                          <>
                                            <>
                                              {items.index.includes("categories") ? (
                                                <li className="aa-ListResultCustom aa-ListCustomCategories">
                                                  {getCatgoriesUrl(hit.catData, "").map((cat) => (
                                                    <>
                                                      <a key={cat.id} onMouseOver={() => searchProducts(cat.id, "categories")} className="aa-LinkReference aa-LinkCustomReference" href={cat.url}> {cat.name} </a> 
                                                    </>
                                                  ))}
                                                </li>
                                              ) : 
                                                <li className="aa-ListResultCustom">
                                                  {getCatgoriesUrl(hit.catData, hit.name).map((cat) => (
                                                    <>
                                                      <a key={cat.id} onMouseOver={() => searchProducts(cat.id, "series")} className="aa-LinkReference" href={hit.url}><Highlight hit={hit} attribute="name" tagName="mark" /></a>
                                                    </>
                                                  ))}
                                                </li>
                                              } 
                                            </>
                                          </>
                                      ))}
                                    </ul>
                                  </>
                                )
                                  :
                                  <>
                                    <ul style={{paddingTop: '20px'}}>
                                      {items.hits.map((hit) => (
                                          <>
                                              {items.index.includes("blogs") ? (
                                                <>
                                                  {hit.is_published === 'true' ? (
                                                    <li className="aa-ListResultCustom">
                                                      <a key={hit.id} className="aa-LinkReference" href={hit.url}><Highlight hit={hit} attribute="name" tagName="mark" /> </a>
                                                    </li>
                                                  ) :
                                                    <></>
                                                  }
                                                </>
                                              )
                                              :
                                              <>
                                                <li className="aa-ListResultCustom">
                                                    <a key={hit.id} className="aa-LinkReference" href={hit.url}><Highlight hit={hit} attribute="name" tagName="mark" /></a>
                                                </li>
                                              </>
                                             }
                                          </>
                                      ))}
                                    </ul>
                                  </>
                                }
                                
                            </>
                        ) : 
                            <>
                            </>
                        }
                    </>
                ))}
              </div>
              <ul
                className="aa-List aa-customList"
                style={{
                  flexFlow: "wrap",
                  float: "left"
                }}
              >

                {isShow && (
                    isShowProducts.map((item) => (
                      <>
                        {item.products.map((hit) => (
                          <>  
                            <li key={hit.id} className="aa-Item" style={{ width: '100%', border: 'solid 1px #dedede', marginBottom: '5px'}}>
                            <a href={hit.url} className="aa-ItemLink" style={{padding: '5px 5px 5px 5px'}}>
                              <div className="aa-ItemContentBody">
                                <div className="aa-ItemContent aa-customItemContent">
                                  <div className="aa-ItemIcon aa-ItemIcon--picture aa-ItemIcon--alignTop">
                                    <img
                                      src={hit.image ? hit.image : defaultPicture}
                                      alt={hit.name}
                                      width="20"
                                      height="20"
                                    />
                                  </div>
                                  <div className="aa-ItemContentBody">
                                    <div className="aa-ItemContentTitle">
                                      <div className="aa-ItemContentTitle">{hit.name}</div>
                                    </div>
                                    <div className="aa-ItemContentDescription">
                                        {
                                          //custom for osg view
                                          getSubstituteData(hit.customFields) ? 
                                            (<>
                                              <strong>Alternatives: </strong> {getSubstituteData(hit.customFields).value}
                                              <br />
                                            </>) : (<>{""}</>)
                                        }
                                        { hit.brand ? (
                                          <>Brand: <strong>{hit.brand}</strong> <br /></>
                                          ) :
                                          (<>{""}</>)
                                        }
                                        { hit.categories.length ? (
                                          <>Series: <strong>{hit.categories[0]}</strong> <br /></>
                                          ) :
                                          (<>{""}</>)
                                        }
                                    </div>
                                    {/* Temporarily hide this for now:
                                    <div
                                      className="aa-ItemContentDescription"
                                      style={{ color: "#000" , overflow: 'hidden'}}
                                    >
                                      <strong style={{ fontSize: '1.2rem'}}>${hit.price.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      })}</strong>
                                    </div>
                                    */}
                                  </div>
                                  <div>
                                    <a className="custom-viewDetails" href={hit.url}>View Details</a>
                                  </div>
                                </div>
                                
                              </div>
                            </a>
                          </li>
                          </>
                        ))}
                       
                      </>
                    ))
                )}

                {!isShow && hits.map((items) => (
                    <>  
                        {items.hits && items.index.includes("products") ? (
                            <>
                                {items.hits.map((hit) => (
                                    <li key={hit.id} className="aa-Item" style={{ width: '100%', border: 'solid 1px #dedede', marginBottom: '5px'}}>
                                    <a href={hit.url} className="aa-ItemLink" style={{padding: '5px 5px 5px 5px'}}>
                                      <div className="aa-ItemContentBody">
                                        <div className="aa-ItemContent aa-customItemContent">
                                          <div className="aa-ItemIcon aa-ItemIcon--picture aa-ItemIcon--alignTop">
                                            <img
                                              src={hit.image ? hit.image : defaultPicture}
                                              alt={hit.name}
                                              width="20"
                                              height="20"
                                            />
                                          </div>
                                          <div className="aa-ItemContentBody">
                                            <div className="aa-ItemContentTitle">
                                              <Highlight hit={hit} attribute="name" tagName="mark" />
                                            </div>
                                            <div className="aa-ItemContentDescription">
                                                {
                                                  //custom for osg view
                                                  getSubstituteData(hit.customFields) ? 
                                                    (<>
                                                      <strong>Alternatives: </strong> {getSubstituteData(hit.customFields).value}
                                                      <br />
                                                    </>) : (<>{""}</>)
                                                }
                                                { hit.brand ? (
                                                  <>Brand: <strong>{hit.brand}</strong> <br /></>
                                                  ) :
                                                  (<>{""}</>)
                                                }
                                                { hit.categories.length ? (
                                                  <>Series: <strong>{hit.categories[0]}</strong> <br /></>
                                                  ) :
                                                  (<>{""}</>)
                                                }
                                            </div>
                                            {/* Temporarily hide this for now:
                                            <div
                                              className="aa-ItemContentDescription"
                                              style={{ color: "#000" , overflow: 'hidden'}}
                                            >
                                              <strong style={{ fontSize: '1.2rem'}}>${hit.price.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                              })}</strong>
                                            </div>
                                            */}
                                          </div>
                                          <div>
                                            <a className="custom-viewDetails" href={hit.url}>View Details</a>
                                          </div>
                                        </div>
                                        
                                      </div>
                                    </a>
                                  </li>
                                ))}
                            </>
                        ) : 
                            <>
                            </> 
                        }
                    </>
                ))}
              </ul>
            </div>

            
          </section>
        </div>
        <div className="autocomplete-footer">
          <div onClick={openModal} className="autocomplete-footer__stats" style={{fontWeight: 'normal'}}>
            <strong>
              <Stats
                translations={{
                  stats(nbHits) {
                    return `See all matching results for "${currentRefinement}" (${countHits.toLocaleString()})`;
                  },
                }}
              />
            </strong>
          </div>
        </div>
      </>
    );
  }

  if (
    currentRefinement.length === 0 ||
    Number.isNaN(countHits)
  ) {
    return <></>;
  }

  return (
    <>
      <div className="aa-Panel aa-osgCustomPanel">{hitLists}</div>
    </>
  );
};
export default DisplayHits;
