import React from "react";

const FilterCount = ({items}) => {
  let cnt = 0;
  items.map(item => {
    if( item.id === 'categories'){
        cnt++;
    }
    if( item.id === 'brand'){
        cnt += item.items.length
    }
  });
  return (
    <>
      {items.length > 0 && <span className="filter-count-container"> {cnt}</span> }
    </>
  );
};

export default FilterCount;