import React from "react";
import { connectHits } from "react-instantsearch-dom";
import Hit from "./hit";
import KimballHit from "./custom/kimballHit";

const Hits = ({ hits, defaultPicture, custom }) => {
  // PUT THE IS MOBILE LOGIC HERE
  return (
    <>
      <div className="ais-Hits">
        <ol className="unified-custom-hits">
          {hits.map((hit) => (
            <li key={hit.id} className="">

              {
                custom === "kimball" &&
                <KimballHit hit={hit} defaultPicture={defaultPicture} custom={custom} />
              }

              {
                custom === "none" || custom === "osg" &&
                <Hit hit={hit} defaultPicture={defaultPicture} />
              }
              
            </li>
          ))}
        </ol>
      </div>
    </>
  );
};

export default connectHits(Hits);
