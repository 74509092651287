import { connectHitsPerPage } from 'react-instantsearch-dom';

const HitsPerPage = ({ items, refine }) => (
    <div className='ais-HitsPerPage'>
        <label className="col-md-12">
            Show

            <select 
                className='k-custom-select ais-HitsPerPage-select w-15' 
                onChange={ event => {
                    event.preventDefault(); 
                    refine(event.target.value);
                }}
            >
                {items.map(item => (
                    <option 
                        className="ais-HitsPerPage-option" 
                        style={{ fontWeight: item.isRefined ? 'bold' : '' }} 
                        key={item.value} 
                        value={item.value}
                    >
                    {item.label}
                    </option>
                ))}
            </select>

            entries
        </label>
    </div>
);

const CustomHitsPerPage = connectHitsPerPage(HitsPerPage);

export default CustomHitsPerPage;