import { AccountLayout, TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";
import {
  Keypair,
  SystemProgram,
  PublicKey,
  SYSVAR_CLOCK_PUBKEY,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
} from "@solana/web3.js";
import serialize from "../util/borsh";
import { map } from "jquery";

const AUCTION_PREFIX = "auction";
const BIDDER_POT_TOKEN = "bidder_pot_token";
const EXTENDED = "extended";

export function toLamports(account, mint) {
  if (!account) {
    return 0;
  }

  const amount =
    typeof account === "number" ? account : account.info.amount?.toNumber();

  const precision = Math.pow(10, mint?.decimals || 0);
  return Math.floor(amount * precision);
}

export function ensureWrappedAccount(
  instructions,
  cleanupInstructions,
  toCheck,
  payer,
  amount,
  signers
) {
  if (toCheck && !toCheck.info.isNative) {
    return toCheck.pubkey;
  }

  const account = Keypair.generate();
  instructions.push(
    SystemProgram.createAccount({
      fromPubkey: payer,
      newAccountPubkey: account.publicKey,
      lamports: amount,
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID,
    })
  );

  instructions.push(
    Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      WRAPPED_SOL_MINT,
      account.publicKey,
      payer
    )
  );

  cleanupInstructions.push(
    Token.createCloseAccountInstruction(
      TOKEN_PROGRAM_ID,
      account.publicKey,
      payer,
      payer,
      []
    )
  );

  signers.push(account);

  return account.publicKey.toBase58();
}

export function approve(
  instructions,
  cleanupInstructions,
  account,
  owner,
  amount,
  autoRevoke = true,

  // if delegate is not passed ephemeral transfer authority is used
  delegate,
  existingTransferAuthority
) {
  const tokenProgram = TOKEN_PROGRAM_ID;

  const transferAuthority = existingTransferAuthority || Keypair.generate();
  //const delegateKey = delegate ?? transferAuthority.publicKey;

  instructions.push(
    Token.createApproveInstruction(
      tokenProgram,
      account,
      delegate ?? transferAuthority.publicKey,
      owner,
      [],
      amount
    )
  );

  if (autoRevoke) {
    cleanupInstructions.push(
      Token.createRevokeInstruction(tokenProgram, account, owner, [])
    );
  }

  return transferAuthority;
}

export const WRAPPED_SOL_MINT = new PublicKey(
  "So11111111111111111111111111111111111111112"
);

export const toPublicKey = (key) => {
  if (typeof key !== "string") {
    return key;
  }

  const result = new PublicKey(key);

  return result;
};

export async function placeBid(
  bidderPubkey,
  bidderTokenPubkey,
  bidderPotTokenPubkey,
  tokenMintPubkey,
  transferAuthority,
  payer,
  resource,
  amount,
  instructions
) {
  const auctionProgramId = "auctxRXPeJoc4817jDhf4HbjnhEcr1cCXenosMhK5R8";

  const data = Buffer.from(
    serialize(
      AUCTION_SCHEMA,
      new PlaceBidArgs({
        resource,
        amount,
      })
    )
  );

  const auctionKey = (
    await findProgramAddress(
      [
        Buffer.from(AUCTION_PREFIX),
        toPublicKey(auctionProgramId).toBuffer(),
        toPublicKey(resource).toBuffer(),
      ],
      toPublicKey(auctionProgramId)
    )
  )[0];

  const bidderPotKey = await getBidderPotKey({
    auctionProgramId,
    auctionKey,
    bidderPubkey,
  });

  const bidderMetaKey = (
    await findProgramAddress(
      [
        Buffer.from(AUCTION_PREFIX),
        toPublicKey(auctionProgramId).toBuffer(),
        toPublicKey(auctionKey).toBuffer(),
        toPublicKey(bidderPubkey).toBuffer(),
        Buffer.from("metadata"),
      ],
      toPublicKey(auctionProgramId)
    )
  )[0];
  let bidderPotTokenAccount;
  if (!bidderPotTokenPubkey) {
    bidderPotTokenAccount = toPublicKey(
      (
        await findProgramAddress(
          [
            Buffer.from(AUCTION_PREFIX),
            toPublicKey(bidderPotKey).toBuffer(),
            Buffer.from(BIDDER_POT_TOKEN),
          ],
          toPublicKey(auctionProgramId)
        )
      )[0]
    );
  } else {
    bidderPotTokenAccount = toPublicKey(bidderPotTokenPubkey);
  }

  const keys = [
    {
      pubkey: toPublicKey(bidderPubkey),
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: toPublicKey(bidderTokenPubkey),
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: toPublicKey(bidderPotKey),
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: bidderPotTokenAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: toPublicKey(bidderMetaKey),
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: toPublicKey(auctionKey),
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: toPublicKey(
        await getAuctionExtended({ auctionProgramId, resource })
      ),
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: toPublicKey(tokenMintPubkey),
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: toPublicKey(transferAuthority),
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: toPublicKey(payer),
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_CLOCK_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
  ];

  instructions.push(
    new TransactionInstruction({
      keys,
      programId: toPublicKey(auctionProgramId),
      data: data,
    })
  );

  return {
    amount,
  };
}

export const pubkeyToString = (key = "") => {
  return typeof key === "string" ? key : key?.toBase58() || "";
};

class PlaceBidArgs {
  instruction = 6;
  resource;
  amount;
  constructor(args) {
    this.resource = args.resource;
    this.amount = args.amount;
  }
}

const AUCTION_SCHEMA = new Map([
  [
    PlaceBidArgs,
    {
      kind: "struct",
      fields: [
        ["instruction", "u8"],
        ["amount", "u64"],
        ["resource", "pubkeyAsString"],
      ],
    },
  ],
]);

export const findProgramAddress = async (seeds, programId) => {
  const result = await PublicKey.findProgramAddress(seeds, programId);

  return [result[0].toBase58(), result[1]];
};

async function getBidderPotKey({ auctionProgramId, auctionKey, bidderPubkey }) {
  return (
    await findProgramAddress(
      [
        Buffer.from(AUCTION_PREFIX),
        toPublicKey(auctionProgramId).toBuffer(),
        toPublicKey(auctionKey).toBuffer(),
        toPublicKey(bidderPubkey).toBuffer(),
      ],
      toPublicKey(auctionProgramId)
    )
  )[0];
}

async function getAuctionExtended({ auctionProgramId, resource }) {
  return (
    await findProgramAddress(
      [
        Buffer.from(AUCTION_PREFIX),
        toPublicKey(auctionProgramId).toBuffer(),
        toPublicKey(resource).toBuffer(),
        Buffer.from(EXTENDED),
      ],
      toPublicKey(auctionProgramId)
    )
  )[0];
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
