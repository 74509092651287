import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { faGlobe, faLink, faPhone, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";

const LocalDistributor = ({ localDistributors, distanceInKM }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleCollapse = () => {
        setIsCollapsed(isCollapsed => !isCollapsed);
    }

    const locaDist = useMemo(() => localDistributors.map((local) => {
        const { 
            objectID,
            id,
            city,
            state,
            address,
            phone,
            website,
            companyName,
            coordinates,
        } = local;
        
        return (
            <div key={objectID} id={id} className={`accordion-body accordion-body--local`}>
                <div className="accordion-item accordion-distance local-distance">
                    <FontAwesomeIcon className="accordion-icon" icon={faWarehouse} />
                    <p>
                        <span>{distanceInKM(coordinates[0], coordinates[1])} mi</span>
                        {/* <FontAwesomeIcon icon={faMapPin} /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>
                    </p>
                </div>
                <h3>{companyName}</h3>
                <p className="accordion-item accordion-location local-loc">
                    {/* <FontAwesomeIcon icon={faMapPin} /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>
                    <span>{city}, {state}</span>
                </p>
                {
                    phone && (
                        <p className="accordion-item accordion-phone local-phone">
                            <FontAwesomeIcon icon={faPhone} />
                            <span>{phone}</span>
                        </p>
                    )
                }
                {
                    website && (
                        <p className="accordion-item accordion-web local-url">
                            <FontAwesomeIcon icon={faGlobe} />
                            <a href={`${website}`}>{website}</a> 
                        </p>
                    )
                }
                <p className="accordion-item accordion-url local-url">
                    <FontAwesomeIcon icon={faLink} />
                    <a href={`https://www.google.com/maps/place/${address.replace(/\n/g, ', ')}`} target="_blank">Get Directions To Local Distributor</a>
                </p>
            </div>
    )}), [localDistributors]);

    return (
        <>
            <div className="accordion-header accordion-header--local" id="localDistributor" onClick={handleCollapse}>
                <span>Local Distributor ({localDistributors.length})</span>
                <FontAwesomeIcon className="accordion-header-icon" icon={isCollapsed ? faCaretDown : faCaretUp} />
            </div>
            {locaDist?.length 
            ? (
                <div className={`${isCollapsed ? 'collapsed' : ''}`}>
                    {locaDist}
                </div>
            ) 
            : (
                <div className={`accordion-body accordion-body--local ${isCollapsed && 'collapsed'}`}>
                    <p>No local distributor found.</p>
                </div>
            )}
        </>
    )
}

export default LocalDistributor;