import React from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";

import { sendTransactionWithRetry } from "../../metaplex/util/connection";
import { sendRedeemBid } from "../../metaplex/services/claim";

import {
  getAuctionView
} from "../../metaplex/services/auctionView";
import { setupPlaceBid } from "../../metaplex/services/buy";

import GetCustomAttr from "./getCustomAttr";
import getShopAddress  from "./shopAddress";
import getAmountInSol  from "./amount";
import updateProduct from "../../utils/updateProduct";
import {showLoader,hideLoader,allowBtn,disableBtn,displayError,displayText} from "../../utils/utils";
import $ from "jquery";

const BuyNow = ({productData}) => {
    const { connection } = useConnection();
    const wallet = useWallet();
    const totalAmount = getAmountInSol(GetCustomAttr(productData.products.custom_fields,'Price in SOL'));
    const shopAddress = getShopAddress(GetCustomAttr(productData.products.custom_fields,'Owner Address'));
    const auctionId = getShopAddress(GetCustomAttr(productData.products.custom_fields,'Auction Pubkey'));
    const auctionPubkey = auctionId.toBase58();
    const AMOUNT = totalAmount;

    let infoText = "";
    if( wallet.connected ){
        infoText = `Your wallet is ${wallet.publicKey.toString()}.`;
    }else{
        infoText = `Click the button to connect your wallet.`;
    }

    disableBtn('claim');      
    if( productData.products.availability != 'available') {
        disableBtn('buy');
        if( productData.products.availability_description === wallet.publicKey.toBase58()) {            
            allowBtn('claim');
            displayText('Please claim your NFT by clicking on the button below.','NFT-SOLD');
        }else{
            displayText('This NFT is already sold and yet to be claim by the buyer.','NFT-SOLD');
        }
    } 
      
    const claimButton = async () => {
      displayText('','');
      showLoader();            
      displayText('Please check your wallet and approve the transaction...','NFT-SOLD');
      const auctionView = await getAuctionView(auctionPubkey, connection, shopAddress);      
      try {        
        displayText('Transaction is in progress...','NFT-SOLD');      
        await sendRedeemBid(connection, wallet, auctionView);                   
        await updateProduct(productData.products.id, 'hide');        
        hideLoader();
        disableBtn('buy');
        disableBtn('claim');
        displayText('You successfully claim your NFT. You can check them in your wallet.','solana-success');
      } catch (e) {
        console.log("🚀 ~ file: buy.jsx ~ line 366 ~ buyButton ~ error", e);
        displayError(e); 
      }
    };
  
    const buyButton = async () => {
      displayText('','');
      const auctionView = await getAuctionView(auctionPubkey, connection, shopAddress);
      const commitment = "finalized";
  
      let signers = [];
      let instructions = [];
      showLoader()      
        try{          
            await setupPlaceBid(
                connection,
                wallet,
                wallet.publicKey,
                auctionView,
                AMOUNT,
                instructions,
                signers
            );            
            displayText('Please check your wallet and approve the transaction...','NFT-SOLD');
            const { txid } = await sendTransactionWithRetry(
                connection,
                wallet,
                instructions[0],
                signers[0],
                commitment
            );
            displayText('Transaction is in progress...','NFT-SOLD');
            if (commitment) {
                await connection.confirmTransaction(txid, commitment);           
                await updateProduct(productData.products.id, 'unavailable', wallet.publicKey.toBase58()); 
                hideLoader()    
                displayText('Transaction is successful. You can now claim your NFT.','solana-success');
                disableBtn('buy');
                allowBtn('claim');
            }
        }catch(e){
          displayError(e);       
        }
    };
  
    return (
      <>
        <button
          onClick={buyButton}
          className="buy-btn wallet-adapter-button wallet-adapter-button-trigger"
        >
          Buy Now
        </button>
        <button
          onClick={claimButton}
          className="claim-btn wallet-adapter-button wallet-adapter-button-trigger"
        >
          Claim NFT
        </button>
      </>
    );
  };
  
  export default BuyNow;
