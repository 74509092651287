import { typesenseSearchClient } from "../utils/typesenseSearchClient"
import { InstantSearch, Hits, MenuSelect  } from "react-instantsearch-dom";

import Footer from "./components/footer";
import CustomHits from "./components/hits";
import CustomHitsPerPage from "./components/hit-per-page";
import CustomSearchBox from "./components/custom-searchbox";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const App = ({ config }) => {

    const searchClient = typesenseSearchClient(config).searchClient;

    return (
        <>
            <InstantSearch searchClient={searchClient} indexName={config.index}>

                <div className="row m-auto k-custom-hit-per-page-container">
                    <div className="col-sm-12 col-md-6">
                        <CustomHitsPerPage 
                            defaultRefinement={10}
                            items={[
                                { label: '10', value: 10 },
                                { label: '25', value: 25 },
                                { label: '50', value: 50 },
                                { label: '100', value: 100 },
                                { label: '250', value: 250 },
                            ]}
                        />
                    </div>

                    <div className="col-sm-12 col-md-3 k-custom-filter-box">
                        <MenuSelect 
                            attribute="brand"
                            facetOrdering={true}
                            translations={{
                                seeAllOption: 'All Brands',
                            }}
                        />
                    </div>
                   

                    <CustomSearchBox />
                </div>

                <SimpleBar>
                    <div className="k-custom-container">
                      <section className="unified-container__hits ">
                        <CustomHits hits={Hits} />
                        <Footer />
                      </section>
                    </div>
                </SimpleBar>
            </InstantSearch>
        </>
    );


};

export default App;