import $ from "jquery";
export function showLoader() {
  return $(".solana-loader")
    .addClass("solana-loader-css")
    .css("display", "flex");
}
export function hideLoader() {
  return $(".solana-loader")
    .addClass("solana-loader-css")
    .css("display", "none");
}

export function allowBtn(btn) {
  if (btn === "buy") {
    return $(".buy-btn").removeAttr("disabled");
  } else {
    return $(".claim-btn").removeAttr("disabled");
  }
}

export function disableBtn(btn) {
  if (btn === "buy") {
    return $(".buy-btn").attr("disabled", "disabled");
  } else {
    return $(".claim-btn").attr("disabled", "disabled");
  }
}

export function displayError(e) {
  console.log(e);
  hideLoader();
  let errtext = "";
  if (e.toString().includes("rejected")) {
    errtext = "The transaction was decline by the user.";
  } else if (e.toString().includes('"Custom":1')) {
    errtext = "You do not have enough funds to proceed with this transaction.";
  } else {
    errtext = e;
  }
  displayText(errtext, "solana-error");
  setTimeout(function () {
    $(".solana-multi-wrapper p").addClass("solana-normal").html(infoText);
    displayText("", "");
  }, 5000);
}
export function displayText(text, status) {
  const $container = $(".solana-content-error");
  let data;
  if (status === "NFT-SOLD") {
    data = $container
      .addClass(status)
      .removeClass("solana-success solana-error");
  } else if (status === "solana-success") {
    data = $container.addClass(status).removeClass("NFT-SOLD solana-error");
  } else if (status === "solana-error") {
    data = $container.addClass(status).removeClass("NFT-SOLD solana-success");
  } else {
    data = $container.removeClass("NFT-SOLD solana-success solana-error");
  }

  return data.html(text);
}
