import React from "react";
import Autocomplete from "./autocomplete/autocomplete";

const searchButton = ({
  openModal,
  defaultPicture,
  custom,
  documents,
  searchPlaceholder
}) => {
  
  return (
    <>
      {/* <div style={{ position: "relative" }}> */}
      <div>
        <Autocomplete openModal={openModal} defaultPicture={defaultPicture} custom={custom} documents={documents} searchPlaceholder={searchPlaceholder} />
      </div>
    </>
  );
};

export default searchButton;
